import { COLORS } from "components/STYLE_CONFIG";
import { Header1, Header2, Paragraph, MultiLineParagraph, TextLinkA } from "components/Typography";
import { default as React, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import ApplicationResource from "resources/application";
import ProfileResource from "resources/profile";
import ProjectResource from "resources/project";
import SettingsResource from "resources/settings";
import StudentResource from "resources/student";
import UserResource from "resources/user";
import { useFetcher, useResource } from "rest-hooks";
import { Breadcrumb, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ErrorScreen } from "./ErrorScreen";
import { ReviewApplicationModal } from "./ReviewApplicationModal";

interface Project {
  id: string;
  application: Readonly<ApplicationResource>;
}

export const PageWrapper = styled.div`
  padding: 50px 140px;
  background: white;
`;

export const ReviewApplicantsPage: React.FC = () => {
  const [settingsList, user, students] = useResource(
    [SettingsResource.listShape(), {}],
    [UserResource.detailShape(), {}],
    [ProfileResource.listShape(), {}]
  );

  const settings = settingsList[0];

  let eligible_students = students;

  {/* ----- COMMENTED OUT -----
  if (settings.faculty_review_only_curis_fellows_enabled) {
    eligible_students = students.filter((student) => student.is_curis_fellow);
  } else {
    eligible_students = students.filter((student) => !student.is_curis_fellow);
  } ----- COMMENTED OUT -----*/}

  const eligible_students_ids = eligible_students.map(
    (profile) => profile.user_id
  );

  if (user.is_student && !user.is_faculty_or_assistant)
    return (
      <ErrorScreen
        header="Page not available"
        message="The page you were trying to access isn’t available to students."
        showReturn
      />
    );

  if (!settings.faculty_rate_student_applications_enabled)
    return (
      <ErrorScreen
        header="Page not available"
        message="The page you were trying to access isn’t currently available because faculty can't rate applications yet."
        showReturn
      />
    );

  return (
    <ReviewApplicantsPageContent
      eligible_students_ids={eligible_students_ids}
    />
  );
};

export const ReviewApplicantsPageContent = ({
  eligible_students_ids,
}: {
  eligible_students_ids: String[];
}) => {
  let { id } = useParams();

  const project = useResource(ProjectResource.detailShape(), { id });

  const applications = useResource(ApplicationResource.listShape(), {})
    .filter(
      (app) =>
        app.project_id === id &&
        app.faculty_ranking !== -1 &&
        eligible_students_ids.includes(app.created_by_user_id)
    )
    .sort((a, b) => a.faculty_ranking - b.faculty_ranking);

  // Applications that were not viewed
  const unread_applications = useResource(
    ApplicationResource.listShape(),
    {}
  )
    .filter((app) => 
      app.project_id === id && 
      !app.faculty_has_viewed &&
      eligible_students_ids.includes(app.created_by_user_id))
    .sort((a, b) => a.faculty_ranking - b.faculty_ranking);
  
  // Applications that were viewed and not qualified
  const unqualified_applications = useResource(
    ApplicationResource.listShape(),
    {}
  )
    .filter((app) => 
      app.project_id === id && 
      app.faculty_has_viewed && 
      app.faculty_ranking === -1 &&
      eligible_students_ids.includes(app.created_by_user_id))
    .sort((a, b) => a.faculty_ranking - b.faculty_ranking);

  // Applications that were viewed and qualified
  const ranked_applications = useResource(
    ApplicationResource.listShape(),
    {}
  )
    .filter((app) => 
      app.project_id === id && 
      app.faculty_has_viewed && 
      app.faculty_ranking !== -1 &&
      eligible_students_ids.includes(app.created_by_user_id))
    .sort((a, b) => a.faculty_ranking - b.faculty_ranking);

  const projects = ranked_applications.map((application, i) => ({
    id: `${i + 1}`,
    application,
  }));
  const n_projects = projects.length;

  const [loading, setLoading] = useState(false);
  const updateApplication = useFetcher(
    ApplicationResource.partialUpdateShape()
  );

  const handleProjRankingChange = async (projects: Project[]) => {
    setLoading(true);
    const updates: { id: string; faculty_ranking: number }[] = [];
    projects.forEach((updated: Project, i: number) => {
          updates.push({
              id: updated.application.id,
              faculty_ranking: i,
          });
    });
    // console.log(updates);
    await Promise.all(
        updates.map((update) =>
            updateApplication(
                { id: update.id },
                {
                    faculty_ranking: update.faculty_ranking,
                }
            )
        )
    );
    setLoading(false);
};

  useEffect(() => {
    handleProjRankingChange(projects);
  }, [n_projects]);

  return (
    <PageWrapper>
      <Breadcrumb style={{ marginBottom: "21px" }} size="large">
        <Breadcrumb.Section link>
          {project.name.replace(/(.{30})..+/, "$1 …")}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Student Applications</Breadcrumb.Section>
      </Breadcrumb>
      <br />
      <Header1>Student Applications</Header1>
      <div style={{ marginTop: "30px", marginBottom: "40px", width: "568px" }}>
        <Paragraph>
          Drag and drop applicants to rank students in order of your preference
          for who should be matched to your project. You must open all
          application and confirm that you’ve read each one to ensure an
          accurate ranking. Rankings are automatically saved and reflected by the "Your Ranking" indicator on each application.
        </Paragraph>
      </div>

      <div style={{ marginTop: "50px", marginBottom: "30px" }}>
        <Header2>Match ranking</Header2>
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <b>Applications in this section will be eligible to match with your project, according to your specified ranking. If you rank an applicant here, you are committed to potentially matching with that applicant.</b>
        </div>

        {ranked_applications.length === 0 && (
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            Applications will appear here if you've indicated that they should be included in match ranking.
          </div>
        )}

        <div style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
          {/* <NumbersWrapper>
            {ranked_applications.map((e, i) => (
              <div key={i}>
                <Header2 color={COLORS.DARK_RED}>{i + 1}</Header2>
              </div>
            ))}
          </NumbersWrapper> */}
          <ApplicationRanking
            initial={projects}
            onChange={handleProjRankingChange}
          />
        </div>
      </div>


      <div style={{ marginTop: "50px", marginBottom: "30px" }}>
        <Header2>Excluded from match ranking</Header2>
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <b>Applications in this section will not be eligible to match with your project.</b>
        </div>
        {unqualified_applications.length > 0 && (
          <>
            <div style={{ marginTop: "10px" }}></div>
            {unqualified_applications.map((application) => (
              <UnqualifiedApplicationItem
                key={application.id}
                application={application}
              />
            ))}
          </>
        )}
        {unqualified_applications.length === 0 && (
          <>
            <div style={{ marginTop: "10px" }}>
              Applications will appear here if you've indicated that they should be excluded from match ranking.
            </div>
          </>
        )}
      </div>


      <div style={{ marginTop: "50px", marginBottom: "30px" }}>
        <Header2>Unread Applications</Header2>
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <b>Applications in this section have not yet been reviewed to be included or excluded from the match ranking.</b>
        </div>
        {unread_applications.length > 0 && (
          <>
            <div style={{ marginTop: "10px", marginBottom: "20px" }}></div>
            {unread_applications.map((application) => (
              <UnreviewedApplicationItem
                key={application.id}
                application={application}
              />
            ))}
          </>
        )}
        {unread_applications.length === 0 && (
          <>
            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              Looks like you've reviewed all applications! Applications will appear here if you have marked them as unread.
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

const UnqualifiedApplicationItem = ({
  application,
}: {
  application: ApplicationResource;
}) => {
  const applicant = useResource(StudentResource.listShape(), {
    user_id: application.created_by_user_id,
  })![0];

  return (
    <ReviewApplicationModal
      application_id={application.id}
      trigger={
        <SimpleQuote key={application.id} inPortal={false}>
          <ApplicationItemContent
            application={application}
            applicant={applicant}
          />
        </SimpleQuote>
      }
    />
  );
};

const UnreviewedApplicationItem = ({
  application,
}: {
  application: ApplicationResource;
}) => {
  const applicant = useResource(StudentResource.listShape(), {
    user_id: application.created_by_user_id,
  })![0];

  return (
    <ReviewApplicationModal
      application_id={application.id}
      trigger={
        <SimpleQuote key={application.id} inPortal={false}>
          <ApplicationItemContent
            application={application}
            applicant={applicant}
          />
        </SimpleQuote>
      }
    />
  );
};

const NumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 10px;

  & div {
    width: 78px;
    text-align: center;
  }

  margin-right: 20px;
`;

interface ItemProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  project: Project;
}

const portal: HTMLElement = document.createElement("div");
portal.classList.add("my-super-cool-portal");

if (!document.body) {
  throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

const SimpleQuote = styled.div<{ inPortal: boolean }>`
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 11px 13px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const get_visible_ranking = (r: number, has_viewed: boolean): any => {
  if (!has_viewed) {
    return "N/A";
  } else if (r >= 0) {
    return (r + 1);
  } else {
    return "N/A";
  }
}

const ApplicationItemContent = ({
  application,
  applicant,
}: {
  application: ApplicationResource;
  applicant: StudentResource;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <div style={{ marginBottom: "0px", marginTop: "5px" }}>
            <p style={{color: COLORS.DARK_RED, fontWeight: "bold"}}> Your Ranking: {get_visible_ranking(application.faculty_ranking, application.faculty_has_viewed)}</p>
          </div>
          <div>
            <Paragraph>
              <b>{applicant.name}</b>{" "}
              {applicant.preferred_email && (
                <Paragraph>
                  |{" "}
                  <TextLinkA href={`mailto:${applicant.preferred_email}`}>
                    <i>{applicant.preferred_email}</i>
                  </TextLinkA>{" "}
                </Paragraph>
              )}
              {applicant.major ? (
                <Paragraph>| {applicant.major} </Paragraph>
              ) : (
                <Paragraph color={COLORS.MED_GREY}>| Missing Major </Paragraph>
              )}
              {applicant.graduation_year ? (
                <Paragraph>| {applicant.graduation_year}</Paragraph>
              ) : (
                <Paragraph color={COLORS.MED_GREY}>
                  | Missing School Year
                </Paragraph>
              )}
              {applicant.resume && (
                <TextLinkA
                  target="_blank"
                  rel="noopener noreferrer"
                  href={applicant.resume}
                >
                  | Resume
                </TextLinkA>
              )}
              {applicant.transcript && (
                <TextLinkA
                  target="_blank"
                  rel="noopener noreferrer"
                  href={applicant.transcript}
                >
                  | Transcript
                </TextLinkA>
              )}
            </Paragraph>
          </div>
          {/* <Paragraph color={COLORS.MED_GREY}>
                        Status: {application.faculty_has_viewed ? "Reviewed" : "Not Reviewed"}
                    </Paragraph> */}
        </div>
        {!application.faculty_has_viewed && (
          <Icon
            style={{ color: COLORS.MED_RED }}
            name="warning circle"
            size="large"
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "5px",
        }}
      >
        {applicant.is_curis_fellow && (
          <Chip bg={"#175e54"} white>
            CURIS Fellow
          </Chip>
        )}
        {applicant.is_cs_declared_by_mar1 && (
          <Chip bg={"#53284f"} white>
            CS declared
          </Chip>
        )}
        {applicant.is_cs_coterm && (
          <Chip bg={"#b26f16"} white>
            CS Coterm
          </Chip>
        )}
        {applicant.is_graduating_in_june && (
          <Chip bg={"#b26f16"} white>
            Graduating
          </Chip>
        )}
      </div>

      <div style={{ marginBottom: "0px", marginTop: "5px" }}>
        <Paragraph>
          <i>Statement of Interest:</i>
        </Paragraph>{" "}
        <MultiLineParagraph>
          <div style={{ wordWrap: "break-word" }}>
            {application.statement.replace(/(.{150})..+/, "$1 …")}
          </div>
        </MultiLineParagraph>
      </div>
    </div>
  );
};

const PortalAwareItem: React.FC<ItemProps> = (props) => {
  const provided: DraggableProvided = props.provided;
  const snapshot: DraggableStateSnapshot = props.snapshot;
  const project: Project = props.project;

  const usePortal: boolean = snapshot.isDragging;

  const application = useResource(ApplicationResource.detailShape(), {
    id: project.application.id,
  });

  const applicant = useResource(StudentResource.listShape(), {
    user_id: project.application.created_by_user_id,
  })![0];

  const child = (
    <SimpleQuote
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      inPortal={usePortal}
    >
      <ApplicationItemContent application={application} applicant={applicant} />
    </SimpleQuote>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal);
};

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const Chip = styled.div<{ bg: string; white?: boolean }>`
  font-size: 12px;
  font-weight: bold;
  padding: 0px 5px;
  border-radius: 4px;
  margin-right: 5px;
  background-color: ${(props) => props.bg};
  color: ${(props) => (props.white ? "white" : "black")};
`;

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ApplicationRanking: React.FC<{
  initial: Project[];
  onChange: (projects: Project[]) => void;
}> = (props) => {
  const [orderedProjects, setOrderedProjects] = useState(props.initial);

  useEffect(() => {
    setOrderedProjects(props.initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initial.length]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const newProjects = reorder(
      orderedProjects,
      result.source.index,
      result.destination.index
    );

    setOrderedProjects(newProjects);
    props.onChange(newProjects);
  };

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided: DroppableProvided) => (
            <Container
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {orderedProjects.map((project: Project, index: number) => (
                <Draggable
                  draggableId={project.id}
                  index={index}
                  key={project.id}
                >
                  {(
                    draggableProvided: DraggableProvided,
                    draggableSnapshot: DraggableStateSnapshot
                  ) => (
                    <ReviewApplicationModal
                      application_id={project.application.id}
                      trigger={
                        <PortalAwareItem
                          project={project}
                          provided={draggableProvided}
                          snapshot={draggableSnapshot}
                        />
                      }
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};
