import React from "react";
import OptionsResource from "resources/options";
import ProjectResource from "resources/project";
import { useResource } from "rest-hooks";
import { List } from "semantic-ui-react";
import styled from "styled-components";
import { capitalize } from "./helpers";
import { ProjectType } from "./ProjectsPage";
import { LabeledList } from "./SimpleList";
import { ListLink } from "./Typography";

export const ProjectCardWrapper = styled(List.Item)<{ selected?: boolean }>`
    background: ${(props) => (props.selected ? "rgba(0, 108, 184, 0.08)" : "white")};
    padding: 20px !important;

    // &: hover {
    //     cursor: pointer;
    // }
`;

export const ProjectListItem: React.FC<{
    project: Readonly<ProjectResource>;
    selected: string;
    projectType: ProjectType;
}> = ({ project, selected, projectType }) => {
    const options = useResource(OptionsResource.detailShape(), {});

    return (
        <ProjectCardWrapper selected={project.id === selected}>
            <ListLink to={project.id}>{project.name}</ListLink>
            <div style={{ height: "5px" }} />
            <LabeledList
                bold
                labels={
                    projectType === ProjectType.SUMMER
                        ? ["Faculty", "Fields"]
                        : projectType === ProjectType.YR_CS
                        ? ["Faculty", "Fields", "Quarter", "Compensation"]
                        : ["Faculty", "Department", "Quarter", "Compensation"]
                }
                items={
                    projectType === ProjectType.SUMMER
                        ? [
                              project.contact_professor_name,
                              project.fields_of_research
                                  .map(
                                      (field) =>
                                          options.field_options.find(
                                              ({ value }) => value === field.toLowerCase()
                                          )?.text || field
                                  )
                                  .join(", ")
                                  .replace(/(.{25})..+/, "$1 …"),
                          ]
                        : projectType === ProjectType.YR_CS
                        ? [
                              project.contact_professor_name,
                              project.fields_of_research
                                  .map(
                                      (field) =>
                                          options.field_options.find(
                                              ({ value }) => value === field.toLowerCase()
                                          )?.text || field
                                  )
                                  .join(", ")
                                  .replace(/(.{25})..+/, "$1 …"),
                              capitalize(project.school_quarter),
                              options.department_options.find(
                                  ({ value }) => value === project.compensation_type
                              )?.text || project.cap_compensation_type,
                          ]
                        : [
                              project.contact_professor_name,
                              options.department_options.find(
                                  ({ value }) => value === project.department
                              )?.text || project.department,
                              capitalize(project.school_quarter),
                              options.department_options.find(
                                  ({ value }) => value === project.compensation_type
                              )?.text || project.cap_compensation_type,
                          ]
                }
            />
        </ProjectCardWrapper>
    );
};
