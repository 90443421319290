export const FONT_FAMILY = "Lato";

export enum COLORS {
    DARK_RED = "#8C1515",
    MED_RED = "#B1040E",
    WHITE = "#FFFFFF",
    DARK_GREY = "#2E2D29",
    BEIGE = "#DAD7CB",
    LIGHT_GREY = "#F4F4F4",
    LIGHT_BLUE = "#006CB8",
    HOVER_BLUE = "#00548F",
    MED_GREY = "#BFBFBF",
    BLACK = "#000000",
}

export enum FONT_SIZES {
    sm = "14px",
    md = "18px",
    lg = "28px",
    xl = "48px",
}

export enum LINE_HEIGHTS {
    sm = "22px",
    md = "29px",
    lg = "36px",
    xl = "58px",
}
