import { Resource } from "rest-hooks";
import { BASE_URL } from "./api";
import AuthdResource from "./AuthdResource";

type Option = [string, string];

export default class OptionsResource extends AuthdResource {
    readonly compensation_choices!: Option[];
    readonly department_choices!: Option[];
    readonly field_of_research_options!: Option[];
    readonly quarter_choices!: Option[];
    readonly graduation_year_choices!: Option[];
    readonly academic_year_choices!: Option[];

    pk() {
        return "options";
    }

    get compensation_options() {
        return this.compensation_choices.map((option) => ({
            key: option[0],
            text: option[1],
            value: option[0],
        }));
    }

    get department_options() {
        return this.department_choices.map((option) => ({
            key: option[0],
            text: option[1],
            value: option[0],
        }));
    }

    get non_cs_department_options() {
        return this.department_choices
            .map((option) => ({
                key: option[0],
                text: option[1],
                value: option[0],
            }))
            .filter((option) => option.value !== "CS");
    }

    get field_options() {
        return this.field_of_research_options.map((option) => ({
            key: option[0],
            text: option[1],
            value: option[0],
        }));
    }

    get quarter_options() {
        return this.quarter_choices.map((option) => ({
            key: option[0],
            text: option[1],
            value: option[0],
        }));
    }

    get non_summer_quarter_options() {
        return this.quarter_choices
            .map((option) => ({
                key: option[0],
                text: option[1],
                value: option[0],
            }))
            .filter((option) => option.value !== "summer");
    }

    get graduation_year_options() {
        return this.graduation_year_choices.map((option) => ({
            key: option[0],
            text: option[1],
            value: option[0],
        }));
    }

    get academic_year_options() {
        return this.academic_year_choices.map((option) => ({
            key: option[0],
            text: option[1],
            value: option[0],
        }));
    }

    static url<T extends typeof Resource>(this: T): string {
        return BASE_URL + "/api/projects/options/";
    }
}
