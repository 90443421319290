import { COLORS } from "components/STYLE_CONFIG";
import { default as React, useState } from "react";
import { Header1, Header2, Header3, Paragraph } from "components/Typography";
import styled from "styled-components";
import flowchartimgUrl from "assets/research_course_flowchart.jpeg";
import { CommonContentBlock} from "components/ContentBlock";

const Image = styled.img`
    width: 90%;
    margin: 15px;
`;

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const About: React.FC = () => {
    return (
        <PageWrapper>
            <Header1 color={COLORS.DARK_RED}>CS Undergraduate Research</Header1>
            <div style={{ marginTop: "30px", marginBottom: "40px"}}>
                <Paragraph>
                Stanford faculty and students do amazing research. The projects here are widely recognized as some of the best in the world and Stanford holds its reputation as one of the top CS programs in large part from this. Your time at Stanford offers a unique opportunity to be involved in cutting-edge research.The are many ways to learn about research opportunities:
                </Paragraph>
            </div>

                <Header2 color={COLORS.DARK_RED} > CURIS Mentors </Header2><br/>
                <Paragraph>
                    <a href="/contact/">CURIS Mentors</a> are undergraduate and graduate students available to provide guidance about general research and answer questions about the CURIS program. They have experience doing research in various groups and can help answer questions about the research experience at Stanford. CURIS mentors hold office hours weekly and by appointment.
                </Paragraph> <br/><br/>

                <Header2 color={COLORS.DARK_RED} > Talk to a Professor </Header2><br/>

			<Paragraph>Find a professor whose research interests you want to learn more about. Discuss what possibilities are available or just find out more about what a particular group is doing. Often the professor will be able to direct you to some research papers that might be valuable to read or some other groups that you might find interesting. It's always a good idea to email a professor and let him or her know that you will be coming in. That way if their office hours are particularly busy, they can suggest another time. </Paragraph><br /><br />

            <Header2 color={COLORS.DARK_RED} > Take a research class </Header2><br />
			<Paragraph> Undergraduate research is often done through CURIS, for academic credit, or through an informal arrangement with a professor. </Paragraph><br /><br />

			<Header3>Independent Study Research Classes (191, 191W, 195, 199/399)</Header3><br />
			<Paragraph>CS 191 is a one quarter research project that fulfills the Senior Project requirement. 191W is the Writing in the Major version of this course. Students must set up their own project, find a faculty sponsor, and submit a proposal. More information is available at the Senior Project website. </Paragraph><br /><br />
			<Paragraph>CS 195 is a one quarter research project that fulfills a CS elective on the undergraduate program sheet (up to 4 units) for students who are not yet eligible to take CS 191. Students must work under faculty supervision and get prior approval for their project. </Paragraph><br /><br />
			<Paragraph>CS 199 (undergrad) and CS 399 (masters) are one- quarter independent studies for students who have already taken 4 units of CS 195, but are not eligible for CS 191. These units will count towards the university-wide graduation requirements, but not toward the CS major itself. </Paragraph><br /><br />
			<Paragraph>For all of these classes, students can propose a project of their own or can find and receive approval for a project listed on the Undergraduate CS Research website. All students must get prior approval from their faculty sponsor, at the very least, before signing up from any of these classes. </Paragraph><br /><br />
			<Header3>Research Project Course (294, 294W) </Header3><br />
			<Paragraph>Students can also opt for CS 294 (or 294W, the WIM version of the course) which fulfills the senior project requirement and allows students to get involved in a major ongoing research project. Check the course listing to see which research areas are covered in 294 for any given year. </Paragraph><br /><br />
			<Header3>Research Course Flowchart </Header3><br />
			<Paragraph>Still not sure what course is right for you? That's alright. Take a look at this flow chart:
            </Paragraph><br /><br />

			<Image src={flowchartimgUrl} /><br/> <br/>

			
        </PageWrapper>
        )}
