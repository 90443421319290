import FooterLogoUrl from "assets/footerLogo.svg";
import React from "react";
import styled from "styled-components";
import { COLORS } from "./STYLE_CONFIG";
import { Paragraph } from "./Typography";

const FooterWrapper = styled.div`
    flex-shrink: 0;
    width: 100%;
`;

const FooterTopBorder = styled.div`
    background-color: ${COLORS.BEIGE};
    width: 100%;
    height: 20px;
`;

const FooterContainer = styled.div`
    background-color: ${COLORS.DARK_RED};
    width: 100%;
    padding-top: 66px;
    padding-bottom: 33px;
    padding-left: 121px;
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    background-image: url(${FooterLogoUrl});
    width: 176px;
    height: 99px;
    margin-right: 39px;
`;

const LinksWrapper = styled.div`
    margin-bottom: 19px;
    display: flex;
`;

const FooterLink: React.FC<{ href: string }> = (props) => {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
            href={props.href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "50px", textDecoration: 'none'}}
        >
            <Paragraph color={COLORS.BEIGE}>
                <b>{props.children}</b>
            </Paragraph>
        </a>
    );
};

export const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <FooterTopBorder />
            <FooterContainer>
                <Logo />
                <div>
                    <LinksWrapper>
                        <FooterLink href="https://www.stanford.edu/">Stanford Home</FooterLink>
                        <FooterLink href="https://cs.stanford.edu/">CS Department Home</FooterLink>
                        <FooterLink href="https://support.cs.stanford.edu/hc/en-us">
                            Help Ticket
                        </FooterLink>
                    </LinksWrapper>
                    <Paragraph color={COLORS.BEIGE}>
                        © 2020 Stanford University, Stanford, California 94305
                    </Paragraph>
                </div>
            </FooterContainer>
        </FooterWrapper>
    );
};
