import LogoUrl from "assets/logo.svg";
import React from "react";
import { Link } from "react-router-dom";
import UserResource from "resources/user";
import { useFetcher, useResource } from "rest-hooks";
import styled from "styled-components";
import { COLORS } from "./STYLE_CONFIG";
import Typography from "./Typography";
import Cookies from "universal-cookie";

const NavWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  z-index: 3;
`;

const Logo = styled.div`
  background-image: url(${LogoUrl});
  width: 119.46px;
  height: 20px;
  background-size: 119.46px;
  background-repeat: no-repeat;
  margin: 15px 0;
  margin-right: 20px;
`;

const NavLink: React.FC<{ to: string; last?: boolean }> = (props) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      to={props.to}
      style={{
        marginRight: props.last ? "0" : "50px",
        display: "inline-block",
        padding: "15px 0",
        whiteSpace: "nowrap",
        textDecoration: "none",
      }}
    >
      {/* <Paragraph color={COLORS.MED_RED}>{props.children}</Paragraph> */}
      <Typography size="sm" lineHeight="sm" color={COLORS.MED_RED}>
        {props.children}
      </Typography>
    </Link>
  );
};

export const Nav: React.FC = () => {
  return (
    <NavWrapper>
      <Link to="/" style={{ display: "flex", alignItems: "center" }}>
        <Logo />
      </Link>
      <div>
        <NavLink to="/profile/">My Profile</NavLink>
        <NavLink to="/applications/">My Applications</NavLink>
        <NavLink to="/faculty/"> Faculty Portal </NavLink>
        <NavLink to="/admin/">Admin Portal</NavLink>
        <NavLink to="/contact/" last>
          Contact
        </NavLink>
      </div>
    </NavWrapper>
  );
};
