import { ReactComponent as ErrorIcon } from "assets/error-icon.svg";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CurisButton from "./CurisButton";
import { Header1, Paragraph } from "./Typography";

const Wrapper = styled.div`
    display: flex;
    // padding: 85px 140px;
    align-items: center;
`;

export const ErrorScreen: React.FC<{
    header: string;
    message: string;
    showReturn?: boolean;
    noPad?: boolean;
}> = ({ header, message, showReturn, noPad }) => {
    return (
        // <div style={{ flex: "1 0 auto" }}>
        <Wrapper style={{ padding: "85px 140px" }}>
            <Wrapper>
                <div style={{ marginRight: "80px" }}>
                    <ErrorIcon />
                </div>
                <div>
                    <div style={{ marginBottom: "30px" }}>
                        <Header1>{header}</Header1>
                    </div>
                    <div style={{ marginBottom: "40px" }}>
                        <Paragraph>{message}</Paragraph>
                    </div>
                    {showReturn && (
                        <Link to="/">
                            <CurisButton>Return home</CurisButton>
                        </Link>
                    )}
                </div>
            </Wrapper>
        </Wrapper>
        // </div>
    );
};
