import React, { Suspense, useState } from "react";
import ApplicationResource from "resources/application";
import ProjectResource from "resources/project";
import { useFetcher, useResource } from "rest-hooks";
import { Button, Form, Icon, Loader, Modal, TextArea } from "semantic-ui-react";
import styled from "styled-components";
import CurisButton from "./CurisButton";
import { Project, ProjectRanking } from "./ProjectRanking";
import { ProjectType } from "./ProjectsPage";
import { COLORS } from "./STYLE_CONFIG";
import { Header2, Paragraph, TextLink } from "./Typography";

export const ApplyModal: React.FC<{
  project: Readonly<ProjectResource>;
  onClose: () => void;
}> = (props) => {
  // const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  // console.log(step);
  const [statement, setStatement] = useState("");
  const { project } = props;

  // default to not summer app and then update below depending on step in process
  let title = "Project Application";
  let content = (
    <Paragraph>
      This is not part of summer CURIS, so you’ll need to reach out to the
      project director directly.
    </Paragraph>
  );
  let button = (
    <a
      href={`mailto:${project.contact_professor_email}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <CurisButton>Open mail application</CurisButton>
    </a>
  );

  const applications = useResource(ApplicationResource.listShape(), {}).sort(
    (a, b) => a.student_ranking - b.student_ranking
  );

  const haveApplied =
    applications.find(
      (application) => application.project_id === project.id
    ) !== undefined;
  // console.log(haveApplied);
  // const applicationMap: Map<string, Readonly<ApplicationResource>> = new Map();
  // applications.forEach((application) => {
  //     applicationMap.set(application.id, application);
  // });

  const createApplication = useFetcher(ApplicationResource.createShape());

  const updateApplication = useFetcher(
    ApplicationResource.partialUpdateShape()
  );

  const [loading, setLoading] = useState(false);

  const [projects, setProjects] = useState<Project[]>([]);

  // console.log("**", step);
  if (project.project_type === ProjectType.SUMMER) {
    if (step === 1) {
      if (haveApplied) {
        setStep(4);
      }
      // console.log(step);
      content = (
        <div>
          <p>
            <Paragraph bold>Statement of Interest</Paragraph>
          </p>
          <Form>
            <StyledTextArea
              onChange={(e: any) => {
                setStatement(e.target.value);
              }}
              placeholder="Please fill in a brief paragraph describing your interest and qualifications for this specific project."
            />
          </Form>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <Icon
              style={{
                color: COLORS.MED_GREY,
                marginRight: "18px",
                paddingTop: "4px",
              }}
              name="warning circle"
              size="large"
            />
            <Paragraph>
              Your information from the{" "}
              <TextLink to={"/profile"}>My Profile</TextLink> page is
              automatically uploaded when you apply. Please ensure that it’s
              updated before submitting.
            </Paragraph>
          </div>
        </div>
      );
      button = (
        <CurisButton
          onClick={async () => {
            setLoading(true);
            await createApplication(
              {},
              {
                project_id: project.id,
                statement,
                student_ranking: applications.length,
              },
              [
                [
                  ApplicationResource.listShape(),
                  {},
                  (newId: string, oldsIDs: string[] | undefined) => [
                    ...(oldsIDs || []),
                    newId,
                  ],
                ],
              ]
            );
            setLoading(false);
            setStep(2);
          }}
          disabled={loading || statement === ""}
        >
          {!loading ? "Apply" : <Loader active inline size="mini" inverted />}
        </CurisButton>
      );
    } else if (step === 2) {
      title = "Project Ranking";
      content = (
        <div>
          <Paragraph>
            Drag and drop the projects you’ve applied to from most to least
            desired. These rankings will be used to determine student project
            matches.{" "}
          </Paragraph>
          <div style={{ marginTop: "30px", marginBottom: "40px" }}>
            <Suspense fallback={<Loader active />}>
              <ProjectRanking
                // initial={projects}
                onChange={(projects) => {
                  setProjects(projects);
                }}
              />
            </Suspense>
          </div>
        </div>
      );
      button = (
        <CurisButton
          onClick={async () => {
            setLoading(true);
            const updates: { id: string; student_ranking: number }[] = [];
            projects.forEach((updated, i) => {
              if (i !== updated.application.student_ranking) {
                updates.push({
                  id: updated.application.id,
                  student_ranking: i,
                });
              }
            });
            await Promise.all(
              updates.map((update) =>
                updateApplication(
                  { id: update.id },
                  {
                    student_ranking: update.student_ranking,
                  }
                )
              )
            );
            setLoading(false);
            setStep(3);
          }}
          disabled={loading}
        >
          {!loading ? "Update" : <Loader active inline size="mini" inverted />}
        </CurisButton>
      );
    } else if (step === 3) {
      title = "Thanks for applying!";
      content = (
        <Paragraph>
          You can update your profile or project ranking at any time on the{" "}
          <TextLink to={"/profile"}>My Profile</TextLink> and{" "}
          <TextLink to={"/applications"}>My Applications</TextLink> pages.
        </Paragraph>
      );
      button = (
        <CurisButton
          onClick={() => {
            setStep(1);
            props.onClose();
          }}
        >
          Got it
        </CurisButton>
      );
    } else if (step === 4) {
      title = "You have already applied for this project!";
      content = (
        <Paragraph>
          You can update your profile or project ranking at any time on the{" "}
          <TextLink to={"/profile"}>My Profile</TextLink> and{" "}
          <TextLink to={"/applications"}>My Applications</TextLink> pages.
        </Paragraph>
      );
      button = (
        <CurisButton
          onClick={() => {
            setStep(1);
            props.onClose();
          }}
        >
          Got it
        </CurisButton>
      );
    }
  }

  return (
    // <StyledModal
    //     trigger={
    //         <span
    //             onClick={() => {
    //                 setStep(1);
    //                 setOpen(false);
    //             }}
    //         >
    //             {props.trigger}
    //         </span>
    //     }
    //     size="small"
    // >
    <>
      <HeaderWrapper>
        <Header2 color={COLORS.BLACK}>{title}</Header2>
        <Icon
          onClick={() => {
            setStep(1);
            props.onClose();
          }}
          style={{ color: COLORS.MED_GREY }}
          link
          name="close"
          size="large"
        />
      </HeaderWrapper>
      {content}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "30px",
        }}
      >
        <GreyButton
          style={{ marginRight: "23px" }}
          onClick={() => {
            setStep(1);
            props.onClose();
          }}
        >
          Cancel
        </GreyButton>
        {button}
      </div>
    </>
    // </StyledModal>
  );
};

const StyledTextArea = styled(TextArea)`
  font-family: Lato !important;
  font-size: 18px !important;
  line-height: 29px !important;
  padding: 13px !important;

  min-height: 228px;
  margin-top: 6px;
`;

export const StyledModal = styled(Modal)`
  padding: 30px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GreyButton = styled(Button)`
  color: white !important;
  background-color: ${COLORS.MED_GREY} !important;

  &: hover {
    background-color: ${COLORS.MED_GREY} !important;
  }
`;
