import { capitalize } from "components/helpers";
import { ProjectType } from "components/ProjectsPage";
import { BASE_URL } from "./api";
import AuthdResource from "./AuthdResource";

export default class ProjectResource extends AuthdResource {
  readonly contact_professor_email: string = "";
  readonly contact_professor_name: string = "";
  readonly created_by_user_id: string = "";
  readonly department: string = "";
  readonly description: string = "";
  readonly field_of_research: string = "";
  readonly field_of_research2: string = "";
  readonly field_of_research3: string = "";
  readonly id: string = "";
  readonly name: string = "";
  readonly number_of_students: number = 0;
  readonly number_of_funded_students: number = 0;
  readonly outreach_office_hours: string = "";
  readonly prerequisites: string = "";
  readonly project_url: string = "";
  readonly public_office_hours: string = "";
  readonly recommended_background: string = "";
  readonly school_quarter: string = "";
  readonly school_year: string = "";
  readonly compensation_type: string = "";

  pk() {
    return this.id + "/";
  }

  get cap_compensation_type() {
    return capitalize(this.compensation_type).replace("_", " ");
  }

  get fields_of_research() {
    return [
      capitalize(this.field_of_research),
      capitalize(this.field_of_research2),
      capitalize(this.field_of_research3),
    ].filter((e) => e);
  }

  get project_type() {
    if (this.department === "CS") {
      if (this.school_quarter === "summer") {
        return ProjectType.SUMMER;
      }
      return ProjectType.YR_CS;
    }
    return ProjectType.NON_CS;
  }

  static urlRoot = BASE_URL + "/api/projects/";
}
