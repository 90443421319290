import { COLORS } from "components/STYLE_CONFIG";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CurisButton from "./CurisButton";
import { Header1, Header2, Paragraph } from "./Typography";

interface ContentBlockProps {
    readonly bgColor?: COLORS;
}

const ContentBlock = styled.div<ContentBlockProps>`
    background-color: ${(props) => props.color || COLORS.WHITE};
    width: 100%;
    padding: 30px 60px;
    display: flex;
    flex-flow: row wrap;
`;
export default ContentBlock;

export const CommonContentBlock: React.FC<{
    dark?: boolean;
    header: string;
    description: React.ReactNode;
    buttonText?: string;
    buttonUrl?: string;
    secondButtonText?: string;
    secondButtonUrl?: string;
}> = (props) => {
    return (
        <ContentBlock color={props.dark ? COLORS.LIGHT_GREY : COLORS.WHITE}>
            <div
                style={{
                    flex: "4 0 0",
                    marginRight: "120px",
                    marginBottom: "30px",
                    minWidth: "200px",
                }}
            >
                <Header2>{props.header}</Header2>
            </div>
            <div style={{ flex: "7 0 0", minWidth: "350px" }}>
                <div style={{ marginBottom: props.buttonText ? "30px" : "0" }}>
                    {props.description}
                </div>
                {props.buttonText && props.buttonUrl ? (
                    <Link to={props.buttonUrl}>
                        <CurisButton>{props.buttonText}</CurisButton>
                    </Link>
                ) : null}
                {props.secondButtonText && props.secondButtonUrl ? (
                    <Link to={props.secondButtonUrl} style={{ marginLeft: "8px" }}>
                        <CurisButton>{props.secondButtonText}</CurisButton>
                    </Link>
                ) : null}
            </div>
        </ContentBlock>
    );
};

const HeaderImageWrapper = styled.div<{ imageUrl: string }>`
    width: 100%;
    padding: 30px 60px;
    display: flex;
    background-image: url(${(props) => props.imageUrl});
    position: relative;
`;

const BoxShadow = styled.span<{ gradient: string }>`
    position: absolute;
    width: 545px;
    height: 100%;
    left: 0px;
    top: 0px;
    background: ${(props) => props.gradient};
`;

export const HeaderImage: React.FC<{
    imageUrl: string;
    gradient: string;
    headerText: string;
    description?: string;
}> = (props) => {
    return (
        <HeaderImageWrapper imageUrl={props.imageUrl}>
            <BoxShadow gradient={props.gradient} />
            <span style={{ zIndex: 1 }}>
                <div style={{ width: "472px" }}>
                    <div style={{ marginBottom: "40px" }}>
                        <Header1 color={COLORS.WHITE}>{props.headerText}</Header1>
                    </div>
                    {props.description ? (
                        <Paragraph color={COLORS.WHITE}>{props.description}</Paragraph>
                    ) : null}
                </div>
            </span>
        </HeaderImageWrapper>
    );
};

