import { COLORS } from "components/STYLE_CONFIG";
import { default as React, useState } from "react";
import { Header1, Header2, Header3, Paragraph } from "components/Typography";
import styled from "styled-components";
import flowchartimgUrl from "assets/research_course_flowchart.jpeg";
import { CommonContentBlock } from "components/ContentBlock";
import { Link } from "react-router-dom";

const Image = styled.img`
    width: 90%;
    margin: 15px;
`;

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const PureAdvisor: React.FC = () => {
    return (
        <PageWrapper>
            <Header1 color={COLORS.DARK_RED}>PURE Research Advisor guide</Header1>
            <div style={{ marginTop: "30px", marginBottom: "40px" }}>
                <Paragraph>
                    <div>
                        We are excited to announce the 2024-25 <b>Paid Undergrad Research Experience (PURE)</b>, a program designed to financially support undergraduate students who are interested in getting involved in CS research during the academic year! This program was first launched in Winter 2022 and aims to support first-generation and/or low income (FLI) students by providing compensation for their work as an undergraduate research assistant in a Stanford CS research lab. We especially hope to support students who have little or no prior research experience to help them explore CS research. Acceptance to the program is on a quarterly basis.
                    </div>
                    <br />
                    <div>
                        PURE positions are funded by Federal Work Study (FWS). This is a program by the U.S. goverment for U.S. citizens and U.S. permanent residents. It is available to <a href="https://studentaid.gov/understand-aid/eligibility/requirements/non-us-citizens">certain groups</a> of non-citizens. Federal Work Study is under a larger umbrella of Federal Student Aid. Generally speaking, if students have been given FWS funds to support their education, they should already know: they've filled out a FAFSA (Free Application for Federal Student Aid), been granted Federal student aid, and received a letter from Stanford telling them the amount of money they can earn with FWS. Alternatively, individual faculty members can fund undergraduate research positions through PURE using their own lab funds. 
                    </div>
                    <br />
                    <div>
                        Please ensure that your PURE students have either verified that they have FWS funds or will be receiving funding from your lab before they apply for PURE. We don't want students to go through a great deal of effort only to discover that they don't have funding. Many students on Stanford financial aid do not have FWS funds: the two are not the same.
                    </div>                    
                </Paragraph>
            </div>

            <Header2>Key Details</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                <ul>
                    <li>
                        <b><Link to="/year_round_cs_projects/">Year-round CS Projects</Link></b>: If you would like to host a PURE project, please list a project on this page and indicate your quarter of availability.
                    </li>
                    <li>
                        <b><Link to="/pure_student/">PURE Student Guide</Link></b>: information about PURE for students (feel free to share this link with interested students!)
                    </li>
                    <li>
                        Winter PURE student application overview:
                        <ul>
                            <li>
                                <b>Deadline:</b> 1/10
                            </li>
                            <li>
                            <b><Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSelzeSF3RZ8bxv0MRfs5g70Ei59u2eQ1T0cgCEVje8RGf7DqA/viewform?usp=sf_link" }} target="_blank">Winter application form</Link></b>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Spring PURE student application overview:
                        <ul>
                            <li>
                                <b>Deadline:</b> 4/4
                            </li>
                            <li>
                            <b><Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSfsu9Glhb17IqWkgXbEnH47j5HOoXUrNOe7OyT3NlNnldoOrA/viewform?usp=sf_link" }} target="_blank">Spring application form</Link></b>
                            </li>
                        </ul>
                    </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>Research Advisor Program Expectations</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                Since we hope for this program to serve as a launching point for undergraduates who may have little or no prior experience with research, we expect that research advisors will serve as mentors for the students to help them to successfully onboard to the research lab and contribute to research. We kindly ask that you please not participate in this program if you are not currently prepared to host and help scaffold students who have minimal research experience, as this is a primary goal of this program.
                Listed below are the detailed expectations of involvement in this program as a research advisor:
                <ul>
                    <li><b>General expectations</b>
                        <ul>
                            <li>Research advisors should expect the time commitment of their advising role to be equivalent to that of a student who has enrolled in 3 units of CS 199.   </li>
                            <li>Research labs are expected to provide, at minimum, a 10-minute block on one date in the lab meeting schedule for the student to give a final, end-of-quarter presentation.
                            </li>
                            <li>Research labs are expected to invite the student to the group lab meeting and include them in the scheduling process.</li>

                        </ul>
                    </li>
                    <li><b>PhD student research advisors</b>
                        <ul>
                            <li>Should meet with the student at least once a week.</li>
                            <li>Should be responsive to student communications (as a rule of thumb, there shouldn’t be more than 24 hours of delay in response)</li>
                            <li>Should, at the very least, check in with their students during Week 4 and Week 8 to provide feedback on student progress, hear feedback from the student on how the research project is going, and ensure that the student is on track to meet their end-of-quarter and final presentation goals (this can be incorporated into the regular weekly meetings, but what’s important is that this big-picture check-in should happen at these points in time).</li>
                        </ul>
                    </li>
                    <li><b>Faculty research advisors</b>
                        <ul><li>Should meet with the student at least twice (once at the start of the quarter and once at the end of the quarter).</li></ul>
                    </li>
                    <li>If interested, it would also be wonderful if research advisors made themselves available to mentor beyond the scope of the research project to provide academic and career advice.
                    </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>FAQs</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                <ul>
                    <li> <i>Where does the student compensation come from? Does it need to come from the faculty member/lab?</i>
                        <ul><li>The compensation comes from Stanford through the Federal Work-Study program. Similar to other FWS roles that are on-campus jobs paid for by the university, this program offers a FWS role in the form of a research assistantship with a CS research group! This means that <i>no</i> funding needs to come from the individual faculty member/lab.
                        </li></ul>
                    </li>
                    <li><i>I’d like to work with a student, but they’re not eligible for FWS. Can they still apply for PURE?</i>
                        <ul><li> If a student is not eligible for FWS, they can still apply to PURE if they’ve talked with their research advisor and have received confirmation from the advisor that the lab can provide their own funding for the student. In that case, students can indicate this on the application form, and we can assist with the paperwork.
                        </li></ul>
                    </li>
                    <li><i>How will students be selected for PURE? Are there any limits to the number of students who can take part in the program?</i>
                        <ul><li>Any student who is eligible for FWS and who has confirmed their alignment with a research advisor can take part in PURE. Since funding comes directly through the FWS program, we have no limits on the number of students who can take part in the program. The research proposal is meant to confirm that the research advisor(s) and the student have agreed to work together and are aligned on a research project of sufficient scope for the next quarter.</li></ul>
                    </li>
                    <li><i>I’m already working with undergraduate RAs, and they’re eligible for FWS; can they apply?</i>
                        <ul><li>Yes, if you already have undergrad RAs working with your group and they qualify for FWS, we encourage them to apply to the program! The program is not restricted to students who aren't already connected with a lab.</li></ul>
                    </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>Student Eligibility</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                In order to be eligible for this opportunity, students must be:
                <ul>
                    <li> a currently-enrolled undergraduate student at Stanford </li>
                    <li> eligible for Federal Work-Study </li>
                    <li> planning to conduct research with a CS faculty member </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>Program Benefits</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                Students will receive compensation through Federal Work-Study as hired research assistants for the duration of the quarter for which they applied to the program. Student pay is determined by Stanford <Link to={{ pathname: "https://financialaid.stanford.edu/faculty_staff/undergrad/wage_scale.html" }} target="_blank"> pay scales </Link> and is set at Level 3 (<Link to={{ pathname: "https://financialaid.stanford.edu/faculty_staff/undergrad/wage_scale.html" }} target="_blank">defined here</Link>). We will work with the Stanford Financial Aid Office to coordinate your payment. Students can participate in this program for one quarter at a time and can apply to extend their RA position to additional quarters if they are interested.
                </Paragraph>
            </div>

            <Header2>Program Expectations</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                We expect that students will commit the equivalent of 3 units (~8-10 hours per week) of work to their research assistantship. We also expect students to give a final, end-of-quarter presentation to their lab group; this presents a great opportunity to publicize your research more widely and build experience with research communication!
                </Paragraph>
            </div>

            <Header2>Application Materials</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                <ul>
                    <li><b>500-word Research Proposal:</b> write a proposal that describes the research project that you would like to work on during your research experience. We recommend that you develop this proposal with the research group / research mentor(s) that you intend to work with so that the proposal will be concrete and most directly useful to your RA-ship experience.
                    </li>
                    <li><b>The name and contact details of your research advisors:</b> (both the faculty and PhD/Post-doc research advisors) and confirmation that they have reviewed the URF program infosheet and have agreed to the Research Advisor Program Expectations.
                    </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>Program Goals & Background</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                We recognize that the current mode of operation for academic-year research presents significant challenges for FLI students. Stanford undergraduates typically either (1) enroll in research for units via courses like CS 195 or CS 199, which essentially means that students need to pay (via tuition) to conduct research or (2) participate in research work informally on top of their course load, jobs, and other extracurricular commitments, which presents a high barrier to entry that many students cannot cross. We aim to make research more accessible to FLI students and to set them up for success by enabling them to be compensated for their research work. The current iteration of the program is limited to students who are eligible for Federal Work-Study, but we hope to expand this program in the future, as we recognize that this program does not capture all individuals who are in financial need (for example, international students who are not eligible for FWS).
                </Paragraph>
            </div>

        </PageWrapper>
    )
}
