import { COLORS } from "components/STYLE_CONFIG";
import { default as React, useState } from "react";
import { Header1, Header2, Header3, Paragraph } from "components/Typography";
import { Link } from "react-router-dom";
import CurisButton from "components/CurisButton";
import styled from "styled-components";

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const Fellowship: React.FC = () => {
    return (
        <PageWrapper>
            <Header1 color={COLORS.DARK_RED}>CURIS Fellowships</Header1>
            <div style={{ marginTop: "30px", marginBottom: "40px"}}>
                <Paragraph>
                    <b><a href="https://curis.stanford.edu/">CURIS</a></b> is the undergraduate research program of Stanford's Computer Science Department. Each summer, 100+ undergraduates conduct and participate in computer science research advised and mentored by faculty and Ph.D. students.<br/><br/>

                    CURIS Fellowships provide guaranteed funding for undergraduates to participate in summer research. A fellowship student's stipend is paid for by the department. A student awarded a CURIS fellowship is eligible for an early match with the <b><Link to="/summer_projects/">CURIS project listings</Link></b>, before those listings are open to the broader university. We expect to grant 20-25 Fellowships for the summer of 2024.<br/><br/>

                    CURIS Fellowships are intended for students who are just starting in research. The goal for the Fellowship program is to give a diverse set of less advanced or experienced students an opportunity to participate in research earlier in their academic career. All Stanford undergraduates are eligible for a CURIS Fellowship, but you must have taken CS 106B by the time you apply. You also must not have prior experience working on research in a Stanford CS Lab. Finally, to be eligible, you must retain an active undergraduate status during the summer (cannot be on suspension, cannot be taking a gap year, cannot be on a leave of absence, cannot be paying graduate tuition). Students accepted to the co-term automatically switch to graduate tuition status after 12 quarters, regardless of enrollment, so if you are graduating in June, or will have "flipped" to MS as a co-term automatically after your 12th quarter, you cannot participate. Please double check that you meet these criteria. The recipients of Fellowships in 2023-2024 will be selected with an emphasis on students who:
                    <ul>
                        <li>do not have prior CS research experience,</li>
                        <li>show promise as researchers in their interests and curiosity, and</li>
                        <li>will help make research accessible to a diverse group of students.</li>
                    </ul>

                    Regardless of whether they receive a CURIS Fellowship, all Stanford students are welcome and invited to apply to CURIS positions when they open for application.<br/><br/>

                    CURIS stipends are set by the University. This summer, all students receive a $7,500 stipend, and students may apply for additional need-based support up to $1,500. Awarding of the need-based aid is managed by the Vice Provost for Undergraduate Education (VPUE) in coordination with the Financial Aid office. 
                </Paragraph>
            </div>

			<Header2>Fellowship Application Process</Header2><br/>
			<Paragraph>
                Your application consists of four parts: your resume, transcript, a brief essay on a research paper, and a brief personal statement on your interest and research potential. A subset of applicants will be contacted for an interview in January.<br/><br/>

                Please apply via <a href="https://forms.gle/Yx7Q338EYdPBgMP98">this Google Form</a>. To apply, you will need:
                <ul>
                    <li>A PDF of your resume</li>
                    <li>A PDF of your unofficial Stanford transcript — we recommend including your fall grades if possible</li>
                    <li>A PDF containing both your research essay and personal statement
                        <ul>
                            <li>Research Essay: 500-word essay on a research reading (listed below)</li>
                            <li>Personal Statement: 200-word essay covering your interest in research</li>
                        </ul>
                    </li>
                </ul>

                <Link to={{ pathname: "https://forms.gle/Yx7Q338EYdPBgMP98" }} target="_blank">
                    <CurisButton>Apply here</CurisButton>
                </Link><br/>
            </Paragraph><br/>

            <Header3>TIMELINE</Header3><br/>
			<Paragraph>
		Applications are due on December 21, 2023 so applicants have time to write a thoughtful research statement after the quarter ends. Applications are welcomed from students from all backgrounds. Fellowship awards will be announced in January, so that applicants who do not receive a Fellowship may still apply to CURIS through the regular process. Awards will be made with awareness of and in conformance with the Supreme Court’s June 2023 ruling on affirmative action. Students who are awarded a fellowship may use the CURIS website to find a project to work on.
                <ul>
                    <li>December 21, 2023: Applications are due (11:59pm PT)</li>
                    <li>January 23, 2023: Fellowship results announced</li>
                </ul>
            </Paragraph><br/>

			<Header3>RESEARCH ESSAY</Header3><br/>
			<Paragraph>
                Your application must include an approximately 500-word research essay on one of a selection of recent Research Highlight articles from the Communications of the ACM. These articles highlight recent especially notable results from across the entire field of CS: they are typically a significant rewrite of the original paper in order to make it more broadly accessible. Furthermore, a leader in the paper's field writes an introduction explaining the context for the work and why it is exemplary.<br/><br/>

		We don’t expect an applicant to be able to provide PhD-level commentary on the work. We do ask you to engage with the core ideas introduced by the work. What ideas does this paper open up to you? If you could add one experiment to the paper, what would it be? What’s an interesting way this result could be used that the author’s don’t talk about? Don’t spend more than a paragraph summarizing the paper and don’t waste space nitpicking it: we are looking for evidence of critical thinking and engagement with the material.<br/><br/>

                Please pick from one of these Research Highlight articles. Read both the Introduction (from another researcher) and the article itself:
                <ul>
                    <li>Systems
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3378931">Introduction</a> - <a href="https://doi.org/10.1145/3378933">Pivot Tracing: Dynamic Causal Monitoring for Distributed Systems</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3399740">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/3399742">Spectre Attacks: Exploiting Speculative Execution</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3517215">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/3517217">Cognitive Biases in Software Development</a>
                            </li>
                        </ul>
                    </li>

                    <li>Theory
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3339183">Introduction</a> - <a href="https://dl.acm.org/doi/abs/10.1145/3339185">Heavy Hitters via Cluster-Preserving Clustering</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3394623">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/3394625">Data-Driven Algorithm Design</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3166066">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/3166068">Which Is the Fairest (Rent Division) of Them All?</a>
                            </li>
                        </ul>
                    </li>

                    <li>AI
                        <ul>
                            <li>
                                <a href="https://cacm.acm.org/magazines/2021/8/254296-unveiling-unexpected-training-data-in-internet-video/abstract">Introduction</a> - <a href="https://dl.acm.org/doi/pdf/10.1145/3431283">Unveiling Unexpected Training Data in Internet Video</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3505266">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/3505267">Supporting People with Autism Spectrum Disorders in the Exploration of PoIs: An Inclusive Recommender System</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3470440">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/3470442">Optimal Auctions Through Deep Learning</a>
                            </li>
                        </ul>
                    </li>

                    <li>NLP
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/abs/10.1145/3593013.3594095">Introduction</a> - <a href="https://dl.acm.org/doi/pdf/10.1145/3593013.3594095">Easily Accessible Text-to-Image Generation Amplifies Demographic Stereotypes at Large Scale</a>
                            </li>
                            <li>
                                <a href="https://arxiv.org/abs/1306.6078">Introduction</a> - <a href="https://arxiv.org/pdf/1306.6078.pdf">A Computational Approach to Politeness with Application to Social Factors</a>
                            </li>
                        </ul>
                    </li>

                    <li>HCI
                        <ul>
                            <li>
                                <a href="https://arxiv.org/abs/2304.03442">Introduction</a> - <a href="https://arxiv.org/pdf/2304.03442.pdf">Generative Agents: Interactive Simulacra of Human Behavior</a>
                            </li>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/1978542.1978563">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/1978542.1978564">Skinput: Appropriating the Skin as an Interactive Canvas</a>
                            </li>
                        </ul>
                    </li>

                    <li>Graphics
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3344806">Introduction</a> - <a href="https://dl.acm.org/doi/abs/10.1145/3344808">OpenFab: A Programmable Pipeline for Multimaterial Fabrication</a>
                            </li>
                        </ul>
                    </li>

                    <li>Biocomp
                        <ul>
                            <li>
                                <a href="https://dl.acm.org/doi/10.1145/3441684">Introduction</a> - <a href="https://dl.acm.org/doi/10.1145/3441686">BioScript: Programming Safe Chemistry on Laboratories-on-a-Chip</a>
                            </li>
                        </ul>
                    </li>
                </ul>

                To access the articles, we recommend the Lean Library extension (see <a href="https://library.stanford.edu/using/connecting-e-resources/connect-campus-faq" target="_blank">this page</a> for information on how to install the extension). This extension grants you access to many electronic resources provided through Stanford Libraries!<br/><br/>
            </Paragraph><br/>

            <Header3>PERSONAL STATEMENT</Header3><br/>
			<Paragraph>
                For your personal statement, please write approximately 200 words describing why you want to spend a summer doing research in CURIS. While the research essay is about what you think about the paper you read, the personal statement should be about you, as a person, and your motivations and goals. Tell us about how you got involved in CS and why you are interested in research. Feel free to discuss any relevant academic, extracurricular, or personal experiences that led you here.<br/><br/>
            </Paragraph><br/>
			
        </PageWrapper>
        )}
