import React from "react";
import { useParams } from "react-router-dom";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List as VirtualList } from "react-virtualized";
import ProjectResource from "resources/project";
import { List } from "semantic-ui-react";
import styled from "styled-components";
import { ProjectListItem } from "./ProjectListItem";
import { ProjectType } from "./ProjectsPage";

export const ProjectListWrapper = styled(List)`
    width: 100%;
    height: 100%;
    max-height: inherit;
`;

export const ProjectList: React.FC<{
    projects: Readonly<ProjectResource>[];
    projectType: ProjectType;
}> = (props) => {
    const params = useParams<{
        projectId: string;
    }>();

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
    });

    const renderRow = ({ index, key, style, parent }: any) => {
        return (
            <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                <div style={style}>
                    <ProjectListItem
                        project={props.projects[index]}
                        selected={params.projectId}
                        projectType={props.projectType}
                    />
                </div>
            </CellMeasurer>
        );
    };
    return (
        <ProjectListWrapper divided verticalAlign="middle">
            {/* <VirtualList
                rowCount={props.projects.length}
                width={width}
                height={height}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
                overscanRowCount={3}
            /> */}
            {/* {props.projects.map((project, i) => (
                <ProjectListItem
                    key={i}
                    project={project}
                    selected={params.projectId}
                    projectType={props.projectType}
                />
            ))} */}
            <AutoSizer>
                {({ width, height }) => {
                    return (
                        <VirtualList
                            width={width}
                            height={height}
                            deferredMeasurementCache={cache}
                            rowHeight={cache.rowHeight}
                            rowRenderer={renderRow}
                            rowCount={props.projects.length}
                            overscanRowCount={3}
                            style={{ outline: "none" }}
                        />
                    );
                }}
            </AutoSizer>
        </ProjectListWrapper>
    );
};
