import React, { useState } from "react";
import ApplicationResource from "resources/application";
import ProjectResource from "resources/project";
import { useFetcher, useResource } from "rest-hooks";
import { Button, Divider, Form, Icon, Loader, Modal, TextArea } from "semantic-ui-react";
import styled from "styled-components";
import CurisButton from "./CurisButton";
import { capitalize } from "./helpers";
import { BreakLink } from "./ProjectDetails";
import { LabeledList } from "./SimpleList";
import { COLORS } from "./STYLE_CONFIG";
import { Header2, Paragraph } from "./Typography";
import { useHistory } from "react-router-dom";

export const ApplicationModal: React.FC<{
    trigger: React.ReactNode;
    application_id: ApplicationResource["id"];
}> = (props) => {
    const [open, setOpen] = useState(false);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [accepting, setAccepting] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [confirmingDelete, setConfirmingDelete] = useState(false);

    const application = useResource(ApplicationResource.detailShape(), {
        id: props.application_id,
    });

    const updateApplication = useFetcher(ApplicationResource.partialUpdateShape());

    const deleteApplication = useFetcher(ApplicationResource.deleteShape());

    const [step, setStep] = useState(1);
    const [statement, setStatement] = useState(application.statement);

    const project = useResource(ProjectResource.detailShape(), {
        id: application.project_id,
    });

    const history = useHistory();

    const onDelete = async () => {
        setDeleting(true);
        deleteApplication({ id: application.id }, undefined);
        setOpen(false); // close modal
        history.push("/profile/");
    };

    if (step !== 1) {
        let title = "Offer Response";
        let content =
            application.decision_faculty === "matched"
                ? "You have been admitted to the position you have applied to. Would you like to accept or decline your offer?"
                : "Although you were rejected from the initial match, you can join a waitlist if another student declines their offer. Would you like to join the waitlist?";
        let button = (
            <>
                <CurisButton
                    onClick={async () => {
                        setRejecting(true);
                        await updateApplication(
                            { id: application.id },
                            {
                                decision_student: "denied",
                            }
                        );
                        setRejecting(false);
                        setStep(3);
                    }}
                    disabled={accepting || rejecting}
                >
                    {!rejecting ? (
                        "Decline"
                    ) : (
                        <>
                            {application.decision_faculty === "matched"
                                ? "Declining Offer"
                                : "Declining Waitlist"}{" "}
                            <Loader
                                active
                                inline
                                size="mini"
                                inverted
                                style={{ marginLeft: "5px" }}
                            />
                        </>
                    )}
                </CurisButton>
                <CurisButton
                    onClick={async () => {
                        setAccepting(true);
                        await updateApplication(
                            { id: application.id },
                            {
                                decision_student: "accepted",
                            }
                        );
                        setAccepting(false);
                        setStep(3);
                    }}
                    disabled={accepting || rejecting}
                >
                    {!accepting ? (
                        application.decision_faculty === "matched" ? (
                            "Accept"
                        ) : (
                            "Join"
                        )
                    ) : (
                        <>
                            {application.decision_faculty === "matched"
                                ? "Accepting Offer"
                                : "Joining Waitlist"}{" "}
                            <Loader
                                active
                                inline
                                size="mini"
                                inverted
                                style={{ marginLeft: "5px" }}
                            />
                        </>
                    )}
                </CurisButton>
            </>
        );
        if (step === 3) {
            title = "Thanks for responding!";
            content =
                "Your response has been sent to the project faculty, who will reach out to you shortly.";
            if (application.decision_faculty !== "matched")
                content =
                    application.decision_student === "denied"
                        ? "You are no longer in consideration for this position."
                        : "Your response has been sent to the project faculty, who will reach out to you if space becomes available for this position.";
            button = (
                <CurisButton
                    onClick={async () => {
                        setStep(1);
                        setOpen(false);
                    }}
                    disabled={loading}
                >
                    Got it
                </CurisButton>
            );
        }

        return (
            <>
                {props.trigger}
                <StyledModal size="tiny" open={open}>
                    <HeaderWrapper>
                        <Header2 color={COLORS.BLACK}>{title}</Header2>
                        <Icon
                            onClick={() => setStep(1)}
                            style={{ color: COLORS.MED_GREY }}
                            link
                            name="close"
                            size="large"
                        />
                    </HeaderWrapper>
                    <Paragraph>{content}</Paragraph>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
                        {button}
                    </div>
                </StyledModal>
            </>
        );
    }

    return (
        <StyledModal
            trigger={<span onClick={() => setOpen(true)}>{props.trigger}</span>}
            size="small"
            open={open}
        >
            <HeaderWrapper>
                <div>
                    <div>
                        <Paragraph>{project.name}</Paragraph>
                    </div>
                    <Paragraph color={COLORS.MED_GREY}>
                        Status: {capitalize(application.status)}
                    </Paragraph>
                </div>
                <Icon
                    onClick={() => setOpen(false)}
                    style={{ color: COLORS.MED_GREY }}
                    link
                    name="close"
                    size="large"
                />
            </HeaderWrapper>
            {application.needsAttention && (
                <CurisButton
                    onClick={() => {
                        setStep(2);
                    }}
                >
                    Respond to offer
                </CurisButton>
            )}
            <Divider style={{ borderTop: "2px solid #BFBFBF" }} />
            <Modal.Content scrolling>
                <HeaderWrapper style={{ marginBottom: "0" }}>
                    <p>
                        <Paragraph bold>Statement of Interest</Paragraph>
                    </p>
                    {application.status === "pending" &&
                        (editing ? (
                            <CurisButton
                                size="tiny"
                                onClick={async () => {
                                    setLoading(true);
                                    await updateApplication(
                                        { id: application.id },
                                        {
                                            statement,
                                        }
                                    );
                                    setLoading(false);
                                    setEditing(false);
                                }}
                                disabled={loading || statement === ""}
                            >
                                {!loading ? (
                                    "Update"
                                ) : (
                                    <Loader active inline size="mini" inverted />
                                )}
                            </CurisButton>
                        ) : (
                            <Icon
                                onClick={() => setEditing(!editing)}
                                style={{ color: COLORS.DARK_GREY }}
                                link
                                name="pencil"
                                size="large"
                            />
                        ))}
                </HeaderWrapper>
                <Form>
                    <StyledTextArea
                        onChange={(e: any) => {
                            setStatement(e.target.value);
                        }}
                        placeholder="Please fill in one to two sentences describing your interest and qualifications for this specific project."
                        value={statement}
                        disabled={!editing}
                    // focused={editing}
                    />
                </Form>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                {application.status === "pending" &&
                    (confirmingDelete ? (
                        <CurisButton
                            onClick={() => {
                                // setConfirmingDelete(true);
                                onDelete();
                            }}
                        >
                            {!deleting ? (
                                "CONFIRM deletion"
                            ) : (
                                <Loader active inline size="mini" inverted />
                            )}
                        </CurisButton>
                    ) : (
                        <GreyButton
                            onClick={() => {
                                setConfirmingDelete(true);
                            }}
                        >
                            Delete application
                        </GreyButton>
                    ))}
                </div>
                <Divider style={{ borderTop: "2px solid #BFBFBF" }} />
                <LabeledList
                    bold
                    labels={[
                        "Professor",
                        "Fields",
                        "Quarter",
                        "Compensation",
                        "Positions Available",
                    ]}
                    items={[
                        <Paragraph>
                            {project.contact_professor_name},{" "}
                            <BreakLink href={`mailto:${project.contact_professor_email}`}>
                                {project.contact_professor_email}
                            </BreakLink>
                        </Paragraph>,
                        project.fields_of_research.join(", "),
                        `${capitalize(project.school_quarter)}, ${project.school_year
                        } Academic Year`,
                        project.cap_compensation_type,
                        project.number_of_students,
                    ]}
                />
                {project.project_url && (
                    <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                        <div style={{ marginBottom: "10px" }}>
                            <Paragraph bold>Project URL</Paragraph>
                        </div>
                        <BreakLink href={project.project_url} target="_blank" rel="noopener noreferrer">
                            <p>{project.project_url}</p>
                        </BreakLink>
                    </div>
                )}
                <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <Paragraph bold>Project Description</Paragraph>
                    </div>
                    <Paragraph>{project.description}</Paragraph>
                </div>
                <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <Paragraph bold>Recommended Background</Paragraph>
                    </div>
                    <Paragraph>{project.recommended_background}</Paragraph>
                </div>
                <div style={{ marginTop: "40px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <Paragraph bold>Prerequisites / Preparation</Paragraph>
                    </div>
                    <Paragraph>{project.prerequisites}</Paragraph>
                </div>
            </Modal.Content>
        </StyledModal>
    );
};

const StyledTextArea = styled(TextArea)`
    font-family: Lato !important;
    // font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 29px !important;
    padding: 13px !important;

    min-height: 161px;
    margin-top: 6px;
`;

const StyledModal = styled(Modal)`
    padding: 30px;
`;

const HeaderWrapper = styled.div`
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const GreyButton = styled(Button)`
    color: white !important;
    background-color: ${COLORS.MED_GREY} !important;

    &: hover {
        background-color: ${COLORS.MED_GREY} !important;
    }
`;
