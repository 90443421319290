import React, { useState } from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import ApplicationResource from "resources/application";
import ProjectResource from "resources/project";
import { useResource } from "rest-hooks";
import styled from "styled-components";
import { Paragraph } from "./Typography";

export interface Project {
    id: string;
    application: Readonly<ApplicationResource>;
}

interface ItemProps {
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    project: Project;
}

const portal: HTMLElement = document.createElement("div");
portal.classList.add("my-super-cool-portal");

if (!document.body) {
    throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

const SimpleQuote = styled.div<{ inPortal: boolean }>`
    background: #ffffff;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 11px 13px;
    width: 100%;
    margin-bottom: 10px;
`;

const PortalAwareItem: React.FC<ItemProps> = (props) => {
    const provided: DraggableProvided = props.provided;
    const snapshot: DraggableStateSnapshot = props.snapshot;
    const project: Project = props.project;

    const curis_project = useResource(ProjectResource.detailShape(), {
        id: project.application.project_id,
    });

    const usePortal: boolean = snapshot.isDragging;

    const child = (
        <SimpleQuote
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            inPortal={usePortal}
        >
            <Paragraph>{curis_project.name}</Paragraph>
        </SimpleQuote>
    );

    if (!usePortal) {
        return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
};

const Container = styled.div`
    margin: 0 auto;
    width: 100%;
`;

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const ProjectRanking: React.FC<{
    // initial: Project[];
    onChange: (projects: Project[]) => void;
}> = (props) => {
    const applications = useResource(ApplicationResource.listShape(), {}).sort(
        (a, b) => a.student_ranking - b.student_ranking
    );
    console.log(applications);
    // const projects: Project[] = [];
    const initial = applications.map((application, i) => ({
        id: `${i + 1}`,
        application,
    }));

    const [orderedProjects, setOrderedProjects] = useState(initial);

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }

        const newProjects = reorder(orderedProjects, result.source.index, result.destination.index);

        setOrderedProjects(newProjects);
        props.onChange(newProjects);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(droppableProvided: DroppableProvided) => (
                    <Container
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                    >
                        {orderedProjects.map((project: Project, index: number) => (
                            <Draggable draggableId={project.id} index={index} key={project.id}>
                                {(
                                    draggableProvided: DraggableProvided,
                                    draggableSnapshot: DraggableStateSnapshot
                                ) => (
                                    <PortalAwareItem
                                        project={project}
                                        provided={draggableProvided}
                                        snapshot={draggableSnapshot}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </Container>
                )}
            </Droppable>
        </DragDropContext>
    );
};
