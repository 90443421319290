import { COLORS } from "components/STYLE_CONFIG";
import { Header1, Header2, Paragraph } from "components/Typography";
import { default as React, useState } from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import ApplicationResource from "resources/application";
import ProjectResource from "resources/project";
import UserResource from "resources/user";
import { useFetcher, useResource } from "rest-hooks";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ApplicationModal } from "./ApplicationModal";
import { ErrorScreen } from "./ErrorScreen";
import { capitalize } from "./helpers";

interface Project {
    id: string;
    application: Readonly<ApplicationResource>;
}

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const ApplicationsPage: React.FC<{ summer?: boolean }> = (props) => {
    const user = useResource(UserResource.detailShape(), {});

    if (user.is_faculty_or_assistant)
        return (
            <ErrorScreen
                header="Page not available"
                message="The page you were trying to access is only available to students."
                showReturn
            />
        );
        
    return <ApplicationsPageContent summer={props.summer} />;
};

export const ApplicationsPageContent: React.FC<{ summer?: boolean }> = (props) => {
    const applications = useResource(ApplicationResource.listShape(), {}).sort(
        (a, b) => a.student_ranking - b.student_ranking
    );

    const projects = applications.map((application, i) => ({
        id: `${i + 1}`,
        application,
    }));

    const updateApplication = useFetcher(ApplicationResource.partialUpdateShape());

    return (
        <PageWrapper>
            <Header1>My Applications</Header1>
            <div style={{ marginTop: "30px", marginBottom: "40px", width: "568px" }}>
                <Paragraph>
                    Drag and drop the projects you’ve applied to from most to least desired. These rankings will be used to determine student project matches. Rankings are automatically saved and reflected by the "Your Ranking" indicator on each application.
                </Paragraph>
            </div>
            <div style={{ display: "flex" }}>
                <NumbersWrapper>
                    {applications.map((e, i) => (
                        <div key={i}>
                            <Header2 color={COLORS.DARK_RED}>{i + 1}</Header2>
                        </div>
                    ))}
                </NumbersWrapper>
                <ApplicationRanking
                    initial={projects}
                    onChange={async (projects) => {
                        
                        const updates: { id: string; student_ranking: number }[] = [];
                        projects.forEach((updated, i) => {
                            updates.push({
                                id: updated.application.id,
                                student_ranking: i,
                            });
                        });

                        await Promise.all(
                            updates.map((update) =>
                                updateApplication(
                                    { id: update.id },
                                    {
                                        student_ranking: update.student_ranking,
                                    }
                                )
                            )
                        );
                    }}
                />
            </div>
        </PageWrapper>
    );
};

const NumbersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 10px;

    & div {
        width: 78px;
        text-align: center;
    }

    margin-right: 20px;
`;

interface ItemProps {
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    project: Project;
}

const portal: HTMLElement = document.createElement("div");
portal.classList.add("my-super-cool-portal");

if (!document.body) {
    throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

const SimpleQuote = styled.div<{ inPortal: boolean }>`
    background: #ffffff;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 11px 13px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const get_visible_ranking = (r: number): any => {
    if (r >= 0) {
      return (r + 1);
    } else {
      return "N/A";
    }
}

const PortalAwareItem: React.FC<ItemProps> = (props) => {
    const provided: DraggableProvided = props.provided;
    const snapshot: DraggableStateSnapshot = props.snapshot;
    const project: Project = props.project;

    const usePortal: boolean = snapshot.isDragging;

    const curis_project = useResource(ProjectResource.detailShape(), {
        id: project.application.project_id,
    });

    const application = useResource(ApplicationResource.detailShape(), {
        id: project.application.id,
    });

    const child = (
        <SimpleQuote
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            inPortal={usePortal}
        >
            <div>
                <div>
                    <Paragraph>{curis_project.name}</Paragraph>
                </div>
                <div>
                    <Paragraph color={COLORS.MED_GREY}>
                        Your Ranking: {get_visible_ranking(application.student_ranking)}
                    </Paragraph>
                </div>
                {/* <div>
                    <Paragraph color={COLORS.MED_GREY}>
                        Status: {capitalize(application.status)}
                    </Paragraph>
                </div> */}
            </div>
            {application.needsAttention && (
                <Icon style={{ color: COLORS.MED_RED }} name="warning circle" size="large" />
            )}
        </SimpleQuote>
    );

    if (!usePortal) {
        return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
};

const Container = styled.div`
    margin: 0 auto;
    width: 100%;
`;

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ApplicationRanking: React.FC<{
    initial: Project[];
    onChange: (projects: Project[]) => void;
}> = (props) => {
    const [orderedProjects, setOrderedProjects] = useState(props.initial);

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }

        const newProjects = reorder(orderedProjects, result.source.index, result.destination.index);

        setOrderedProjects(newProjects);
        props.onChange(newProjects);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(droppableProvided: DroppableProvided) => (
                    <Container
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                    >
                        {orderedProjects.map((project: Project, index: number) => (
                            <Draggable draggableId={project.id} index={index} key={project.id}>
                                {(
                                    draggableProvided: DraggableProvided,
                                    draggableSnapshot: DraggableStateSnapshot
                                ) => (
                                    <ApplicationModal
                                        application_id={project.application.id}
                                        trigger={
                                            <PortalAwareItem
                                                project={project}
                                                provided={draggableProvided}
                                                snapshot={draggableSnapshot}
                                            />
                                        }
                                    />
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </Container>
                )}
            </Droppable>
        </DragDropContext>
    );
};
