import 'bootstrap/dist/css/bootstrap.css';
import App from "app/App";
import { ErrorScreen } from "components/ErrorScreen";
import "core-js/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { CacheProvider, NetworkError, NetworkErrorBoundary } from "rest-hooks";
import { Loader } from "semantic-ui-react";
import Cookies from "universal-cookie";
import "whatwg-fetch";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };

    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.error(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <ErrorScreen
                    header="Something Went Wrong :("
                    message="Please try again or file a support ticket."
                />
            );
        }
        return this.props.children;
    }
}

const NetworkErrBoundary = ({ error }: { error: NetworkError }) => {
    if (error.status === 401) {
        const cookies = new Cookies();
        cookies.remove("authToken");
        window.location.href = "/api/authentication/login";
        return null;
    }

    return (
        <ErrorScreen
            header="Something Went Wrong :("
            message="Please try again or file a support ticket."
        />
    );
};

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <CacheProvider>
                <Suspense fallback={<Loader active />}>
                    <NetworkErrorBoundary fallbackComponent={NetworkErrBoundary}>
                        <App />
                    </NetworkErrorBoundary>
                </Suspense>
            </CacheProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
