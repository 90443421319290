import React, { useState } from "react";
import ApplicationResource from "resources/application";
import ProjectResource from "resources/project";
import StudentResource from "resources/student";
import { useFetcher, useResource } from "rest-hooks";
import { Button, Divider, Icon, Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";
import CurisButton from "./CurisButton";
import { BreakLink } from "./ProjectDetails";
import { LabeledList } from "./SimpleList";
import { COLORS } from "./STYLE_CONFIG";
import { Paragraph, MultiLineParagraph } from "./Typography";

export const ReviewApplicationModal: React.FC<{
    trigger: React.ReactNode;
    application_id: ApplicationResource["id"];
}> = (props) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const updateApplication = useFetcher(ApplicationResource.partialUpdateShape());
    
    // const invalidateApplication = useInvalidator(ApplicationResource.detailShape());

    // const { application } = props;
    const application = useResource(ApplicationResource.detailShape(), {
        id: props.application_id,
    });

    const applications = useResource(ApplicationResource.listShape(), {})
        .filter((app) => 
            app.project_id === application.project_id && 
            app.faculty_has_viewed && 
            app.faculty_ranking !== -1)
        .sort((a, b) => a.faculty_ranking - b.faculty_ranking);

    const project = useResource(ProjectResource.detailShape(), {
        id: application.project_id,
    });

    const applicant = useResource(StudentResource.listShape(), {
        user_id: application.created_by_user_id,
    })![0];

    const unread_button = (
        <GreyButton
            onClick={async () => {
                setLoading(true);
                await updateApplication(
                    { id: application.id },
                    {
                        faculty_has_viewed: !application.faculty_has_viewed,
                    }
                );
                setLoading(false);
            }}
            disabled={loading}
        >
            {!loading ? (
                "Mark as Unread"
            ) : (
                <>
                    Marking as Unread{" "}
                    <Loader
                        active
                        inline
                        size="mini"
                        inverted
                        style={{ marginLeft: "5px" }}
                    />
                </>
            )}
        </GreyButton>
    );

    const read_button = (
        <CurisButton
            onClick={async () => {
                setLoading(true);
                await updateApplication(
                    { id: application.id },
                    {
                        faculty_has_viewed: !application.faculty_has_viewed,
                    }
                );
                setLoading(false);
            }}
            disabled={loading}
        >
            {!loading ? (
                "Mark as Read"
            ) : (
                <>
                    Marking as Read{" "}
                    <Loader
                        active
                        inline
                        size="mini"
                        inverted
                        style={{ marginLeft: "5px" }}
                    />
                </>
            )}
        </CurisButton>
    );

    const match_button = (
        <CurisButton
            onClick={async () => {
                setLoading(true);
                await updateApplication(
                    { id: application.id },
                    {
                        faculty_ranking: applications.length > 0 ? applications.length - 1 : applications.length,
                        faculty_has_viewed: true,
                    }
                );
                setLoading(false);
                setOpen(false);
            }}
            disabled={loading}
        >
            {!loading ? (
                "Include application in match ranking"
            ) : (
                <Loader active inline size="mini" inverted />
            )}
        </CurisButton>
    );

    const exclude_match_button = (
        <CurisButton
            onClick={async () => {
                setLoading(true);
                await updateApplication(
                    { id: application.id },
                    {
                        faculty_ranking: -1,
                        faculty_has_viewed: true,
                    }
                );
                setLoading(false);
                setOpen(false);
            }}
            disabled={loading}
        >
            {!loading ? (
                "Exclude application from match ranking"
            ) : (
                <Loader active inline size="mini" inverted />
            )}
        </CurisButton>
    );

    const exclude_match_button_secondary = (
        <GreyButton
            onClick={async () => {
                setLoading(true);
                await updateApplication(
                    { id: application.id },
                    {
                        faculty_ranking: -1,
                        faculty_has_viewed: true,
                    }
                );
                setLoading(false);
                setOpen(false);
            }}
            disabled={loading}
        >
            {!loading ? (
                "Exclude application from match ranking"
            ) : (
                <Loader active inline size="mini" inverted />
            )}
        </GreyButton>
    );

    const match_and_exclude_buttons = (
        <div>
            {exclude_match_button_secondary} {match_button}
        </div>
    );

    return (
        <StyledModal
            trigger={<span onClick={() => setOpen(true)}>{props.trigger}</span>}
            size="small"
            open={open}
        >
            <HeaderWrapper>
                <div>
                    <div>
                        <Paragraph>{applicant.name}</Paragraph>
                    </div>
                    <Paragraph color={COLORS.MED_GREY}>{project.name}</Paragraph>
                </div>
                <Icon
                    onClick={() => setOpen(false)}
                    style={{ color: COLORS.MED_GREY }}
                    link
                    name="close"
                    size="large"
                />
            </HeaderWrapper>
            <Divider style={{ borderTop: "2px solid #BFBFBF" }} />
            <Modal.Content scrolling>
                <LabeledList
                    bold
                    labels={[
                        "Email",
                        "Major",
                        "Academic Year",
                        "Will finish graduate by June?",
                        "Will have declared CS by March?",
                        "Is a current CS coterm?",
                    ]}
                    items={[
                        applicant.preferred_email || "N/A",
                        applicant.major || "N/A",
                        applicant.graduation_year || "N/A",
                        applicant.is_graduating_in_june ? "Yes" : "No",
                        applicant.is_cs_declared_by_mar1 ? "Yes" : "No",
                        applicant.is_cs_coterm ? "Yes" : "No",
                    ]}
                />
                <br />
                {(applicant.transcript || applicant.resume) && (
                    <Divider style={{ borderTop: "2px solid #BFBFBF" }} />
                )}
                {applicant.resume && (
                    <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                        <BreakLink
                            href={applicant.resume}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <p>Resume</p>
                        </BreakLink>
                    </div>
                )}
                {applicant.transcript && (
                    <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                        <BreakLink
                            href={applicant.transcript}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <p>Transcript</p>
                        </BreakLink>
                    </div>
                )}
                <Divider style={{ borderTop: "2px solid #BFBFBF" }} />
                <div style={{ marginBottom: "30px", marginTop: "30px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <Paragraph bold>Statement of Interest</Paragraph>
                    </div>
                    <MultiLineParagraph>{application.statement}</MultiLineParagraph>
                </div>
                {application.faculty_has_viewed ? (
                    unread_button
                ) : (match_and_exclude_buttons)}
                {application.faculty_has_viewed && application.faculty_ranking === -1 ? (
                    match_button
                ) : null}
                {application.faculty_has_viewed && application.faculty_ranking !== -1 ? (
                    exclude_match_button
                ) : null}
            </Modal.Content>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    padding: 30px;
`;

const HeaderWrapper = styled.div`
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const GreyButton = styled(Button)`
    color: white !important;
    background-color: ${COLORS.MED_GREY} !important;

    &: hover {
        background-color: ${COLORS.MED_GREY} !important;
    }
`;
