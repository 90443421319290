import { COLORS } from "components/STYLE_CONFIG";
import { default as React, useState } from "react";
import { Header1, Header2, Paragraph } from "components/Typography";
import styled from "styled-components";
import flowchartimgUrl from "assets/research_course_flowchart.jpeg";
import { CommonContentBlock} from "components/ContentBlock";

const Image = styled.img`
    width: 90%;
    margin: 15px;
`;

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const Contact: React.FC = () => {
    return (
        <PageWrapper>
            <Header2>CURIS Staff</Header2>
            <div style={{ marginTop: "30px", marginBottom: "40px"}}>
                <Paragraph>
                    Listed below are members of the CURIS staff and their role.
                    <ul>
                        <li> Yesenia Gallegos <a href="mailto:ygallego@stanford.edu">(ygallego@stanford.edu)</a>: &nbsp; Student Services officer: CURIS logistics </li>
                        <li> Debby Inenaga <a href="mailto:dinenaga@stanford.edu ">(dinenaga@stanford.edu )</a>: &nbsp; Director of Finance and Operations</li>
                        <li> Michael Bernstein <a href="mailto:msb@cs.stanford.edu">(msb@cs.stanford.edu)</a>: &nbsp; CURIS Faculty Director</li>
                        <li> Sierra Wang <a href="mailto:sierraw@stanford.edu">(sierraw@stanford.edu)</a>: &nbsp; CURIS Mentor</li>
                        <li> Irena Gao <a href="mailto:igao@stanford.edu">(igao@stanford.edu)</a>: &nbsp; CURIS Mentor</li>
                    </ul>

                </Paragraph>
            </div>

            <Header2>CURIS Office Hours</Header2>
            <div style={{ marginTop: "30px", marginBottom: "40px"}}>
                <Paragraph>
                    The CURIS Mentors are graduate students and are available to provide guidance about general research and the CURIS program. Office hours are held weekly and by appointment. To contact the CURIS Mentors, please email <a href="mailto:curis-mentors@cs.stanford.edu">curis-mentors@cs.stanford.edu</a>.
                    <ul>
                        <li> Sierra Wang: &nbsp; <a href="">Calendly signup</a></li>
                        <li> Irena Gao: &nbsp; <a href="">Calendly signup</a></li>
                    </ul>

                </Paragraph>
            </div>

            <Header2>Technical support</Header2>
            <div style={{ marginTop: "30px", marginBottom: "40px"}}>
                <Paragraph>
                    For technical issues related to the CURIS Website, please contact <a href="https://support.cs.stanford.edu/hc/en-us/requests/new">CS support.</a>
                </Paragraph>
            </div>
        </PageWrapper>
        )}
