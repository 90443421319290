import { COLORS, FONT_SIZES, LINE_HEIGHTS } from "components/STYLE_CONFIG";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProjectCardWrapper } from "./ProjectListItem";

interface TypographyProps {
    readonly bold?: boolean;
    readonly size?: "sm" | "md" | "lg" | "xl";
    readonly lineHeight?: "sm" | "md" | "lg" | "xl";
    readonly color?: COLORS;
    readonly multiLine?: boolean;
}

const Typography = styled.span<TypographyProps>`
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    font-size: ${(props) => FONT_SIZES[props.size || "md"]};
    line-height: ${(props) => LINE_HEIGHTS[props.lineHeight || "md"]};
    color: ${(props) => props.color || COLORS.DARK_GREY};
    white-space: ${(props) => (props.multiLine ? "pre-line": "normal")};

    & ul {
        padding-left: 2rem;
    }
`;
export default Typography;

export const Header1: React.FC<{ color?: COLORS }> = (props) => {
    return (
        <Typography bold size="xl" lineHeight="xl" color={props.color}>
            {props.children}
        </Typography>
    );
};

export const Header2: React.FC<{ color?: COLORS }> = (props) => {
    return (
        <Typography bold size="lg" lineHeight="lg" color={props.color}>
            {props.children}
        </Typography>
    );
};

export const Header3: React.FC<{ color?: COLORS }> = (props) => {
    return (
        <Typography bold size="md" lineHeight="md" color={props.color}>
            {props.children}
        </Typography>
    );
};

export const Paragraph: React.FC<{ color?: COLORS; bold?: boolean }> = (props) => {
    return (
        <Typography size="md" lineHeight="md" color={props.color} bold={props.bold}>
            {props.children}
        </Typography>
    );
};

export const MultiLineParagraph: React.FC<{ color?: COLORS; bold?: boolean }> = (props) => {
    return (
        <Typography size="md" lineHeight="md" color={props.color} bold={props.bold} multiLine>
            {props.children}
        </Typography>
    );
};

export const TextLinkA = styled.a`
    font-size: ${FONT_SIZES["md"]};
    line-height: ${LINE_HEIGHTS["md"]};
    text-decoration-line: underline;
    color: ${COLORS.LIGHT_BLUE};
    white-space: nowrap;

    &: hover {
        text-decoration-line: underline;
        color: ${COLORS.HOVER_BLUE};
    }
`;

export const TextLink = styled(Link)`
    font-size: ${FONT_SIZES["md"]};
    line-height: ${LINE_HEIGHTS["md"]};
    text-decoration-line: underline;
    color: ${COLORS.LIGHT_BLUE};
    white-space: nowrap;

    &: hover {
        text-decoration-line: underline;
        color: ${COLORS.HOVER_BLUE};
    }
`;

export const ListLinkA = styled.a`
    font-size: ${FONT_SIZES["md"]};
    line-height: ${LINE_HEIGHTS["md"]};
    text-decoration-line: none;
    color: ${COLORS.LIGHT_BLUE};
    font-weight: bold;

    &: hover {
        text-decoration-line: none;
        color: ${COLORS.HOVER_BLUE};
    }

    // ${ProjectCardWrapper}:hover & {
    //     text-decoration-line: none;
    //     color: ${COLORS.HOVER_BLUE};
    // }
`;

export const ListLink = styled(Link)`
    font-size: ${FONT_SIZES["md"]};
    line-height: ${LINE_HEIGHTS["md"]};
    text-decoration-line: none;
    color: ${COLORS.LIGHT_BLUE};
    font-weight: bold;

    &: hover {
        text-decoration-line: none;
        color: ${COLORS.HOVER_BLUE};
    }

    // ${ProjectCardWrapper}:hover & {
    //     text-decoration-line: none;
    //     color: ${COLORS.HOVER_BLUE};
    // }
`;
