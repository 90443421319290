import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "./STYLE_CONFIG";

export default styled(Button)`
    color: white !important;
    background-color: ${COLORS.DARK_RED} !important;

    &: hover {
        background-color: ${COLORS.MED_RED} !important;
    }
`;
