import { Resource } from "rest-hooks";
import Cookies from "universal-cookie";

export default abstract class AuthdResource extends Resource {
    static fetchOptionsPlugin = (options: RequestInit) => {
        const cookies = new Cookies();
        return {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Token ${cookies.get("authToken")}`,
            },
        };
    };
}
