import { BASE_URL } from "./api";
import AuthdResource from "./AuthdResource";

export default class SettingsResource extends AuthdResource {
  readonly faculty_add_assistants_enabled: boolean = false;
  readonly faculty_add_projects_enabled: boolean = false;
  readonly faculty_rate_student_applications_enabled: boolean = false;
  readonly landing_page_link_enabled: boolean = false;
  readonly student_add_applications_enabled: boolean = false;
  readonly student_edit_profile_enabled: boolean = false;
  readonly student_view_and_accept_project_matches: boolean = false;
  readonly student_view_projects_enabled: boolean = false;
  readonly allow_only_curis_fellows: boolean = false;
  readonly faculty_review_only_curis_fellows_enabled: boolean = false;

  pk() {
    return "idk"; /* only one entry so this is a little weird */
  }

  static urlRoot = BASE_URL + "/api/settings/";
}
