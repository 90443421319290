import { createGlobalStyle } from "styled-components";
import { COLORS, FONT_FAMILY, FONT_SIZES, LINE_HEIGHTS } from "./STYLE_CONFIG";

export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html {
        height: 100%;
    }

    #root {
        height: 100%;
    }
    
    body {
        display: flex;
        flex-direction: column;
        height: 100vh;
        max-width: 100%;
        min-width: 500px;
        font-size: ${FONT_SIZES.md};
        line-height: ${LINE_HEIGHTS.md};
        background-color: ${COLORS.WHITE};
        color: ${COLORS.DARK_GREY};
        font-family: ${FONT_FAMILY};
    }
    `;
