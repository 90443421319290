import React from "react";
import { Paragraph } from "./Typography";

export const SimpleListItem: React.FC<{ bold?: boolean }> = (props) => {
    return (
        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            <Paragraph bold={props.bold}>{props.children}</Paragraph>
        </div>
    );
};

export const SimpleList: React.FC<{ bold?: boolean; items: React.ReactNode[] }> = (props) => {
    return (
        <div>
            {props.items.map((item, id) => (
                <SimpleListItem key={id} bold={props.bold}>
                    {item}
                </SimpleListItem>
            ))}
        </div>
    );
};

export const LabeledList: React.FC<{
    bold?: boolean;
    labels: string[];
    items: React.ReactNode[];
}> = (props) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "64px" }}>
                <SimpleList bold items={props.labels} />
            </div>
            <div>
                <SimpleList items={props.items} />
            </div>
        </div>
    );
};
