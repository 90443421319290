import React, { Suspense, useState } from "react";
import { Form, Icon, Modal, Grid } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "./STYLE_CONFIG";
import { Header2 } from "./Typography";

export const MainModal = ({
  toggle,
  onClose,
  headerText,
  children,
}: {
  toggle: boolean;
  onClose: Function;
  headerText: String;
  children: React.ReactNode;
}) => {
  return (
    <Container>
      <StyledModal size="small" open={toggle}>
        <HeaderWrapper>
          <Header2 color={COLORS.BLACK}>{headerText}</Header2>
          <Icon
            onClick={() => {
              onClose();
            }}
            style={{ color: COLORS.MED_GREY }}
            link
            name="close"
            size="large"
          />
        </HeaderWrapper>
        <Modal.Content>{children}</Modal.Content>
      </StyledModal>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  width: 80%;
`;

const StyledModal = styled(Modal)`
  padding: 30px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
