import { AbstractInstanceType, Resource } from "rest-hooks";
import { BASE_URL } from "./api";
import AuthdResource from "./AuthdResource";

export default class ApplicationResource extends AuthdResource {
    readonly created_by_user_id: string = "";
    readonly faculty_ranking: number = 0;
    readonly is_matched: boolean = false;
    readonly statement: string = "";
    readonly decision_faculty: "pending" | "matched" | "not_matched" = "pending";
    readonly decision_student: "pending" | "accepted" | "denied" = "pending";
    readonly student_ranking: number = 0;
    readonly id: string = "";
    readonly project_id: string = "";
    readonly faculty_has_viewed: boolean = false;

    pk() {
        return this.id;
    }

    get needsAttention() {
        return this.decision_faculty !== "pending" && this.decision_student === "pending";
    }

    get status() {
        if (this.decision_student === "pending") return this.decision_faculty.split("_").join(" ");
        if (this.decision_faculty === "matched") return "offer " + this.decision_student;
        return (this.decision_student === "accepted" ? "joined" : "declined") + " waitlist";
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams: { id: string } & Partial<AbstractInstanceType<T>>
    ): string {
        return BASE_URL + `/api/applications/${urlParams.id}/`;
    }

    static urlRoot = BASE_URL + "/api/applications/";
}
