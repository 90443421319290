import { EmptyProjectDetails } from "components/EmptyProjectDetails";
import { ProjectDetails } from "components/ProjectDetails";
import { ProjectList } from "components/ProjectList";
import { COLORS } from "components/STYLE_CONFIG";
import { Paragraph } from "components/Typography";
import React, { Suspense, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import OptionsResource from "resources/options";
import ProjectResource from "resources/project";
import SettingsResource from "resources/settings";
import ProfileResource from "resources/profile";
import UserResource from "resources/user";
import { NetworkErrorBoundary, useResource } from "rest-hooks";
import { Dropdown, Form, Loader, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { StyledModal } from "./ApplyModal";
import { CreateProjectModal } from "./CreateProjectModal";
import CurisButton from "./CurisButton";
import { ErrorScreen } from "./ErrorScreen";

export const PageWrapper = styled.div`
  padding: 0px 20px;
  background: white;
`;

export const FilterBar = styled.div`
  background: ${COLORS.LIGHT_GREY};
  border: 1px solid ${COLORS.MED_GREY};
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const SelectRow = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled(Form.Input)`
  font-family: Lato !important;
  font-size: 18px !important;
  line-height: 29px !important;
  font-weight: 600 !important;
  color: ${COLORS.DARK_GREY} !important;

  & label {
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 29px !important;
    font-weight: 600 !important;
  }

  & input {
    padding: 13px !important;
  }
`;

export enum ProjectType {
  SUMMER = "SUMMER",
  YR_CS = "CS",
  NON_CS = "NON_CS",
}

export const ProjectsPage: React.FC<{
  projectType: ProjectType;
  showOutreachOH?: boolean;
}> = (props) => {
  const [settingsList, user, profile] = useResource(
    [SettingsResource.listShape(), {}],
    [UserResource.detailShape(), {}],
    [ProfileResource.detailShape(), {}]
  );

  const settings = settingsList[0];

  let error_screen = (
    <ErrorScreen
      header="Summer CURIS Projects"
      message="The Summer CURIS projects have not yet been released to students."
      showReturn
    />
  );

  // Block access to students if student project-viewing is disabled
  // Block access to students who are non-CURIS Fellows and not faculty assistants in CURIS Fellow round
  if (props.projectType === ProjectType.SUMMER) {
    if (user.is_student && !user.is_faculty_or_assistant) {
      // Don't block access unless user is student and non-faculty-assistant
      if (!settings.student_view_projects_enabled) {
        return error_screen;
      } else if (settings.allow_only_curis_fellows) {
        // Check if CURIS Fellow
        if (!profile.is_curis_fellow) return error_screen;
      }
    }
  }

  return <ProjectsPageContent {...props} />;
};

const ProjectsPageContent: React.FC<{
  projectType: ProjectType;
  showOutreachOH?: boolean;
}> = (props) => {
  const settings = useResource(SettingsResource.listShape(), {})[0];
  const [user, options, unfilteredProjects] = useResource(
    [UserResource.detailShape(), {}],
    [OptionsResource.detailShape(), {}],
    [ProjectResource.listShape(), {}]
  );

  const projects = React.useMemo(() => {
    return unfilteredProjects.filter(
      (p) => p.project_type === props.projectType
    );
  }, [unfilteredProjects, props.projectType]);

  const [search, setSearch] = useState("");
  const [professorFilter, setProfessorFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [fieldFilter, setFieldFilter] = useState("");
  const [quarterFilter, setQuarterFilter] = useState("");
  const [compensationFilter, setCompensationFilter] = useState("");

  let { path } = useRouteMatch();

  const professors: Set<string> = new Set();

  projects.forEach((project) => {
    professors.add(project.contact_professor_name);
  });

  const filterFunc = (project: Readonly<ProjectResource>) => {
    return (
      (!professorFilter ||
        project.contact_professor_name.toLowerCase() ===
          professorFilter.toLowerCase()) &&
      (!departmentFilter ||
        project.department.toLowerCase() === departmentFilter.toLowerCase()) &&
      (!fieldFilter ||
        [
          project.field_of_research,
          project.field_of_research2,
          project.field_of_research3,
        ].includes(fieldFilter)) &&
      (!quarterFilter ||
        project.school_quarter.toLowerCase() === quarterFilter.toLowerCase()) &&
      (!compensationFilter ||
        project.compensation_type.toLowerCase() ===
          compensationFilter.toLowerCase()) &&
      (!search ||
        project.name.toLowerCase().includes(search.toLowerCase()) ||
        project.contact_professor_name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        project.field_of_research
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        project.field_of_research2
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        project.field_of_research3
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        project.description.toLowerCase().includes(search.toLowerCase()) ||
        project.prerequisites.toLowerCase().includes(search.toLowerCase()) ||
        project.recommended_background
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        project.department.toLowerCase().includes(search.toLowerCase()))
    );
  };

  const [open, setOpen] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FilterBar>
        <Paragraph bold color={COLORS.BLACK}>
          Search
        </Paragraph>
        <div style={{ width: "60%" }}>
          <StyledInput
            style={{ width: "100%" }}
            size="mini"
            icon="search"
            placeholder="Find a project..."
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        {user.is_faculty_or_assistant && (
          <>
            <StyledModal
              size="small"
              open={open}
              onClose={() => setOpen(false)}
            >
              <CreateProjectModal
                projectType={props.projectType}
                onClose={() => setOpen(false)}
              />
            </StyledModal>
            <CurisButton
              disabled={
                props.projectType === ProjectType.SUMMER &&
                !settings.faculty_add_projects_enabled
              }
              onClick={() => setOpen(true)}
            >
              Create New
            </CurisButton>
          </>
        )}
      </FilterBar>
      <FilterBar>
        <Paragraph bold color={COLORS.BLACK}>
          Filter by
        </Paragraph>
        <SelectRow>
          <Dropdown
            clearable
            search
            selection
            size="small"
            options={dropDownOptions(Array.from(professors))}
            placeholder="Professor"
            style={{ marginRight: "16px" }}
            value={professorFilter}
            onChange={(e, { value }) => {
              setProfessorFilter(value as string);
            }}
          />
          {props.projectType === ProjectType.NON_CS && (
            <Dropdown
              clearable
              search
              selection
              size="small"
              options={options.department_options}
              placeholder="Department"
              style={{ marginRight: "16px" }}
              value={departmentFilter}
              onChange={(e, { value }) => {
                setDepartmentFilter(value as string);
              }}
            />
          )}
          {props.projectType !== ProjectType.NON_CS && (
            <Dropdown
              clearable
              search
              selection
              size="small"
              options={options.field_options}
              placeholder="Research Field"
              style={{
                marginRight:
                  props.projectType !== ProjectType.SUMMER ? "16px" : "",
              }}
              value={fieldFilter}
              onChange={(e, { value }) => {
                setFieldFilter(value as string);
              }}
            />
          )}
          {props.projectType !== ProjectType.SUMMER && (
            <Dropdown
              clearable
              search
              selection
              size="small"
              options={dropDownOptions(
                props.projectType === ProjectType.NON_CS
                  ? ["Autumn", "Winter", "Spring", "Summer"]
                  : ["Autumn", "Winter", "Spring"]
              )}
              style={{ marginRight: "16px" }}
              placeholder="Quarter"
              value={quarterFilter}
              onChange={(e, { value }) => {
                setQuarterFilter(value as string);
              }}
            />
          )}
          {props.projectType !== ProjectType.SUMMER && (
            <Dropdown
              clearable
              search
              selection
              size="small"
              options={options.compensation_options}
              placeholder="Compensation Type"
              value={compensationFilter}
              onChange={(e, { value }) => {
                setCompensationFilter(value as string);
              }}
            />
          )}
        </SelectRow>
      </FilterBar>
      <PageWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "40%",
              height: "calc(100vh - 175px)",
              display: "flex",
              width: "40%",
            }}
          >
            <Switch>
              <Route exact path={path}>
                <ProjectList
                  projects={projects.filter(filterFunc)}
                  projectType={props.projectType}
                />
              </Route>
              <Route path={`${path}/:projectId`}>
                <ProjectList
                  projects={projects.filter(filterFunc)}
                  projectType={props.projectType}
                />
              </Route>
            </Switch>
          </div>
          <div
            style={{
              maxWidth: "55%",
              maxHeight: "calc(100vh - 175px)",
              display: "flex",
              width: "55%",
            }}
          >
            <Switch>
              <Route exact path={path}>
                <EmptyProjectDetails />
              </Route>
              <Suspense
                fallback={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Segment basic>
                      <Loader active />
                    </Segment>
                  </div>
                }
              >
                <NetworkErrorBoundary
                  fallbackComponent={(error) => (
                    <ErrorScreen
                      header="Project not found"
                      message="The project you were trying to access doesn't exist."
                      noPad
                    />
                  )}
                >
                  <Route path={`${path}/:projectId`}>
                    <ProjectDetails
                      projectType={props.projectType}
                      showOutreachOH={props.showOutreachOH}
                    />
                  </Route>
                </NetworkErrorBoundary>
              </Suspense>
            </Switch>
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

const dropDownOptions = (options: string[]) => {
  return options.map((option) => ({ value: option, text: option }));
};
