import { Resource } from "rest-hooks";
import { BASE_URL } from "./api";
import AuthdResource from "./AuthdResource";

export default class AssistantResource extends AuthdResource {
  readonly id: string = "";
  readonly assistant_user_id: string = "";
  readonly faculty_user_id: string = "";

  pk() {
    return this.id;
  }

  /**
   * Get the url for a Resource
   */
  static url<T extends typeof Resource>(this: T): string {
    return BASE_URL + "/api/a/assistants/";
  }

  /**
   * Get the url for a Resource
   */
  static listUrl<T extends typeof Resource>(this: T): string {
    return BASE_URL + "/api/assistants/";
  }
}
