import { ApplicationsPage } from "components/ApplicationsPage";
import { Footer } from "components/Footer";
import { GlobalStyle } from "components/GlobalStyle";
import { Nav } from "components/Nav";
import { ProfilePage } from "components/ProfilePage";
import { ProjectsPage, ProjectType } from "components/ProjectsPage";
import { ReviewApplicantsPage } from "components/ReviewApplicantsPage";
import ScrollToTop from "components/ScrollToTop";
import { default as React, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import Cookies from "universal-cookie";
import { FacultyInformation } from "views/FacultyInformation";
import { HomePage } from "views/HomePage";
import { About } from "views/About";
import { PureStudent } from "views/PureStudent";
import { PureAdvisor } from "views/PureAdvisor";
import { Contact } from "views/Contact";
import { Fellowship } from "views/Fellowship";
import { SummerDetails } from "views/SummerDetails";
import { AdminPage } from "components/AdminPage";

const App: React.FC = () => {
  if (
    window.location.protocol !== "https:" &&
    process.env.NODE_ENV !== "development"
  ) {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <GlobalStyle />
      <div
        className="App"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Nav />
        <div style={{ flex: "1 0 auto" }}>
          <Switch>
            <Suspense fallback={<Loader active />}>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/pure_student">
                <PureStudent />
              </Route>
              <Route exact path="/pure_advisor">
                <PureAdvisor />
              </Route>
              <Route exact path="/curis_fellowships">
                <Fellowship />
              </Route>
              <Route exact path="/summer">
                <SummerDetails />
              </Route>
              <Route path="/summer_projects">
                <AuthRoute>
                  <ProjectsPage projectType={ProjectType.SUMMER} />
                </AuthRoute>
              </Route>
              <Route path="/summer_projects_outreach_oh">
                <AuthRoute>
                  <ProjectsPage
                    projectType={ProjectType.SUMMER}
                    showOutreachOH={true}
                  />
                </AuthRoute>
              </Route>
              <Route path="/year_round_cs_projects">
                <AuthRoute>
                  <ProjectsPage projectType={ProjectType.YR_CS} />
                </AuthRoute>
              </Route>
              <Route path="/projects_outside_cs">
                <AuthRoute>
                  <ProjectsPage projectType={ProjectType.NON_CS} />
                </AuthRoute>
              </Route>
              <Route exact path="/faculty">
                <AuthRoute>
                  <FacultyInformation />
                </AuthRoute>
              </Route>
              <Route exact path="/admin">
                <AuthRoute>
                  <AdminPage />
                </AuthRoute>
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route path="/profile">
                <AuthRoute>
                  <ProfilePage />
                </AuthRoute>
              </Route>
              <Route path="/applications">
                <AuthRoute>
                  <ApplicationsPage />
                </AuthRoute>
              </Route>
              <Route path="/review_applicants/:id">
                <AuthRoute>
                  <ReviewApplicantsPage />
                </AuthRoute>
              </Route>
            </Suspense>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

const AuthRoute = ({ children }: { children: React.ReactNode }) => {
  const cookies = new Cookies();
  const authToken = cookies.get("authToken");

  if (authToken) {
    return <div>{children}</div>;
  }

  cookies.remove("authToken");
  window.location.href = "/api/authentication/login";
  return null;
};

export default App;
