import React from "react";
import styled from "styled-components";
import { COLORS } from "./STYLE_CONFIG";
import Typography from "./Typography";

export const ProjectDetailsWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 135px;
    width: 100%;
`;

export const EmptyProjectDetails: React.FC<{}> = (props) => {
    return (
        <ProjectDetailsWrapper>
            <div style={{ width: "275px", textAlign: "center" }}>
                <Typography color={COLORS.MED_GREY} size="lg" lineHeight="lg" bold>
                    Select a research position to see more details here.
                </Typography>
            </div>
        </ProjectDetailsWrapper>
    );
};
