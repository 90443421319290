import { BASE_URL } from "./api";
import AuthdResource from "./AuthdResource";

export default class UserResource extends AuthdResource {
    readonly affiliation: "student" | "faculty" = "student";
    readonly has_admin_access: boolean = false;
    readonly id: string = "";
    readonly sunet: string = "";
    readonly email: string = "";
    readonly first_name: string = "";
    readonly last_name: string = "";
    readonly is_student: boolean = true;
    readonly is_faculty: boolean = true;
    readonly is_faculty_or_assistant: boolean = true;
    readonly prof_email: string = "";
    readonly prof_name: string = "";
    pk() {
        return this.id;
    }

    get full_name() {
        return this.first_name + (this.last_name && " " + this.last_name);
    }

    static urlRoot = BASE_URL + "/api/authentication/user/";
}
