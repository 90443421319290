import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OptionsResource from "resources/options";
import ProjectResource from "resources/project";
import SettingsResource from "resources/settings";
import UserResource from "resources/user";
import { useResource } from "rest-hooks";
import styled from "styled-components";
import { ApplyModal, StyledModal } from "./ApplyModal";
import CurisButton from "./CurisButton";
import { EditProjectModal } from "./EditProjectModal";
import { capitalize } from "./helpers";
import { ProjectType } from "./ProjectsPage";
import { LabeledList } from "./SimpleList";
import { COLORS, FONT_SIZES, LINE_HEIGHTS } from "./STYLE_CONFIG";
import {Header2, MultiLineParagraph, Paragraph} from "./Typography";

export const ProjectDetailsWrapper = styled.div`
    padding-top: 42px;
    max-height: inherit;
    height: inherit;
    overflow-y: hidden;
    overflow-wrap: break-word;
    width: 100%;
    &: hover {
        overflow-y: scroll;
    }
`;

const TextLinkA = styled.a`
    font-size: ${FONT_SIZES["md"]};
    line-height: ${LINE_HEIGHTS["md"]};
    text-decoration-line: underline;
    color: ${COLORS.LIGHT_BLUE};
    white-space: nowrap;

    &: hover {
        text-decoration-line: underline;
        color: ${COLORS.HOVER_BLUE};
    }
`;

export const BreakLink = styled(TextLinkA)`
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

export const ProjectDetails: React.FC<{ 
    projectType: ProjectType;
    showOutreachOH?: boolean;
}> = ({ projectType, showOutreachOH }) => {
    const params = useParams<{
        projectId: string;
    }>();

    const history = useHistory();

    const [project, user, options] = useResource(
        [ProjectResource.detailShape(), { id: params.projectId }],
        [UserResource.detailShape(), {}],
        [OptionsResource.detailShape(), {}]
    );
    const [open, setOpen] = useState(false);
    const settings = useResource(SettingsResource.listShape(), {})[0];

    return (
        <ProjectDetailsWrapper>
            <Header2>{project.name}</Header2>
            {user.is_student && !user.is_faculty_or_assistant && (
                <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                    <StyledModal size="small" open={open} onClose={() => setOpen(false)}>
                        <ApplyModal project={project} onClose={() => setOpen(false)} />
                    </StyledModal>
                    <CurisButton
                        disabled={
                            projectType === ProjectType.SUMMER &&
                            !settings.student_add_applications_enabled
                        }
                        onClick={() => setOpen(true)}
                    >
                        Apply
                    </CurisButton>
                </div>
            )}
            {user.is_faculty_or_assistant && ( //&& user.id === project.created_by_user_id && (
                <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                    <StyledModal size="small" open={open} onClose={() => setOpen(false)}>
                        <EditProjectModal
                            projectType={projectType}
                            project={project}
                            onClose={() => setOpen(false)}
                        />
                    </StyledModal>
                    <CurisButton onClick={() => setOpen(true)}>Edit Details</CurisButton>
                    {projectType === ProjectType.SUMMER && (
                        <CurisButton
                            onClick={() => history.push(`/review_applicants/${project.id}`)}
                            style={{ marginLeft: "10px" }}
                        >
                            Review applicants
                        </CurisButton>
                    )}
                </div>
            )}
            <LabeledList
                bold
                labels={
                    project.project_type !== ProjectType.NON_CS
                        ? ["Faculty", "Fields", "Quarter", "Compensation", "Positions Available"]
                        : ["Faculty", "Quarter", "Compensation", "Positions Available"]
                }
                items={
                    project.project_type !== ProjectType.NON_CS
                        ? [
                              <Paragraph>
                                  {project.contact_professor_name},{" contact email: "}
                                  <BreakLink href={`mailto:${project.contact_professor_email}`}>
                                      {project.contact_professor_email}
                                  </BreakLink>
                              </Paragraph>,
                              project.fields_of_research
                                  .map(
                                      (field) =>
                                          options.field_options.find(
                                              ({ value }) => value === field.toLowerCase()
                                          )?.text || field
                                  )
                                  .join(", "),
                              `${capitalize(project.school_quarter)}, ${
                                  project.school_year
                              } Academic Year`,
                              options.department_options.find(
                                  ({ value }) => value === project.compensation_type
                              )?.text || project.cap_compensation_type,
                              project.number_of_students,
                          ]
                        : [
                              <Paragraph>
                                  {project.contact_professor_name},{" contact email: "}
                                  <BreakLink href={`mailto:${project.contact_professor_email}`}>
                                      {project.contact_professor_email}
                                  </BreakLink>
                              </Paragraph>,
                              `${capitalize(project.school_quarter)}, ${
                                  project.school_year
                              } Academic Year`,
                              options.department_options.find(
                                  ({ value }) => value === project.compensation_type
                              )?.text || project.cap_compensation_type,
                              project.number_of_students,
                          ]
                }
            />
            {project.project_url && (
                <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <Paragraph bold>Project URL</Paragraph>
                    </div>
                    <BreakLink href={project.project_url} target="_blank" rel="noopener noreferrer">
                        <p>{project.project_url}</p>
                    </BreakLink>
                </div>
            )}
            <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                <div style={{ marginBottom: "10px" }}>
                    <Paragraph bold>Project Description</Paragraph>
                </div>
                <MultiLineParagraph>{project.description}</MultiLineParagraph>
            </div>
            <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                <div style={{ marginBottom: "10px" }}>
                    <Paragraph bold>Recommended Background</Paragraph>
                </div>
                <MultiLineParagraph>{project.recommended_background}</MultiLineParagraph>
            </div>
            <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                <div style={{ marginBottom: "10px" }}>
                    <Paragraph bold>Prerequisites / Preparation</Paragraph>
                </div>
                <MultiLineParagraph>{project.prerequisites}</MultiLineParagraph>
            </div>
            {projectType === ProjectType.SUMMER && (
                <div>
                    <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                        <div style={{ marginBottom: "10px" }}>
                            <Paragraph bold>Project Office Hours</Paragraph>
                        </div>
                        <MultiLineParagraph>{project.public_office_hours}</MultiLineParagraph>
                    </div>
                    {showOutreachOH && (
                        <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                            <div style={{ marginBottom: "10px" }}>
                                <Paragraph bold>Outreach Office Hours</Paragraph>
                            </div>
                            <MultiLineParagraph>{project.outreach_office_hours}</MultiLineParagraph>
                        </div>
                    )}
                </div>
            )}
        </ProjectDetailsWrapper>
    );
};
